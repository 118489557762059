import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CardMedia from '@material-ui/core/CardMedia';
import AlertDialog from "./AlertDialog";
import ChangePassword from "./ChangePassword";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import { red } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        marginTop: 15
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
    },
    wideTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300
    },
    paper:{
         margin:10,
         fontSize:24

    },
    itemCard:{
        marginTop:25,
        marginLeft:5,
    },
    zdUserInfo:{
         marginLeft:10,
         marginBottom:5
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    }
});

const roles = [
    { id: 3, value: 'Courtier' },
    { id: 4, value: 'Technicien Capture' },
    { id: 5, value: 'Technicien Post Production' },
    { id: 6, value: 'Client' },
    { id: 7, value: 'Technicien Injector' },
    { id: 8, value: 'Technicien Post Production Lead' },
    { id: 2, value: 'Admin' }
];

const admin_role = 2;
const broker_role = 3;
const prod_tech_role = 4;
const post_prod_tech_role = 5;
  
var emptyUser = {
    id: null,
    lastname: '',
    firstname: '',
    email: '',
    phone_number: '',
    role: '',
    password: '',
    skills:[],
    zones: [],
    team_id:null,
    broker_code:''
}

class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: emptyUser,
            passwordField: '123456',
            passwordValidationField: '123456',
            loaded: false,
            dlgShow: false,
            passwordRequired:true,
            ZendDesKUser:true,            
            zendDeskUserData:null,
            userSkills : [],
            userShootings: [],
            userHMDS: [],
            teams:[],
            franchises:[],
            depots: [],
            zones: []
        };
    }
    componentWillMount() {

        window.Hauslife.getUserSKills()
            .then((res)=>{
                this.setState({userSkills:res.data.skills});
            }).catch( (e)=>{
                console.log('Error getZendUser : ',e);
            });

        window.Hauslife.getBrokerTeam()
            .then((res)=>{
                this.setState({
                    teams:res.data.result.teams,
                    franchises:res.data.result.franchises
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getDepots()
            .then((res)=>{
                this.setState({
                    depots:res.data.depots
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getZones()
            .then((res)=>{
                this.setState({
                    zones:res
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        if(this.props.match.params.id==undefined) return;

        this.callOnMe('getUserData', this.props.match.params.id);

        if(window.Hauslife.userObject.role <=2){
            window.Hauslife.getZendUser(this.props.match.params.id)
                .then( (res) => {
                    if(res.data.data.id!= undefined){
                        this.setState({zendDeskUserData:res.data.data});
                    } else {
                        this.setState({ZendDesKUser:false});
                    }
                })
                .catch( (e)=>{
                    console.log('Error getZendUser : ',e);
                });
        }
    }

    handleCheckboxChange = name => event => {
        let obj = {};
        obj[name] = !this.state[name];
        this.setState(obj);
    }

    handle_gf_clause_change = name => event => {
        let user = this.state.user;
        user.gf_clause =  !user.gf_clause;
        this.setState(user);
    }

    handleSuspended = event => {
        let user = this.state.user;
        user.suspended=  !user.suspended;
        this.setState(user);
    }
    handleChange = name => event => {
        let user = this.state.user;
        user[name] = event.target.value;
        this.setState({ user });

        // Le changement de role effectera le zendDesk user seulement si c'est 
        // en nouveau utilisateur.

        if(this.state.user.id!=null) {
            return;
        }
        if(name=='role'){
            switch(event.target.value){
                case 3:
                case 4:
                case 5:
                    this.setState({ZendDesKUser:true});
                    break;
                default:
                    this.setState({ZendDesKUser:false});
                    break;
            }
        }
    }

    handleChangePassword = name => event => {
        this.setState({ [name]: event.target.value });
    }

    callOnMe = (service, data) => {        
        window.Hauslife[service](data)
            .then((res) => {
                switch(service){                      
                    case 'getUserData':
                        console.log(res.data)
                        let skills = [];
                        res.data.user.userSkills.forEach( (item)=>{
                            skills.push(item.skill_id);
                        });

                        let zone_id = -1;
                        let backupZones = [];

                        res.data.user.userZones.forEach( (item)=>{
                            if(item.backup == false){
                                zone_id = item.zone.id;
                            }
                            else{
                                backupZones.push(item.zone.id);                                
                            }                            
                        });
    
                        this.setState({
                            user: {
                                id: res.data.user.id,
                                lastname: res.data.user.lastname,
                                firstname: res.data.user.firstname,
                                email: res.data.user.email,
                                phone_number: res.data.user.phone_number,
                                role: res.data.user.role,
                                gf_clause: res.data.user.gf_clause,
                                skills,
                                suspended:res.data.user.suspended,
                                broker_code:res.data.user.broker_code,
                                team_id:res.data.user.team_id,
                                franchise_id:res.data.user.franchise_id,
                                depot_id: res.data.user.depot_id,
                                zone_id: zone_id,
                                backupZones
                            },
                            passwordField: '',
                            passwordValidationField: '',
                            passwordRequired:false,
                            loaded: false,
                            dlgShow: false,
                            dlgTitle: 'Hauslife',
                            dlgText: ""
                        },()=>{
                            if(window.Hauslife.userObject.role <= admin_role)
                            {
                                if(this.state.user.role == prod_tech_role)
                                {
                                    window.Hauslife.getAllShootingsOfTech(this.props.match.params.id)
                                    .then(res=>{
                                        this.setState({userShootings:res.data.shootings});
                                    })
                                    .catch(e=>{
                                        console.log(e);
                                    });
                                }

                                if(this.state.user.role == broker_role)
                                {
                                    window.Hauslife.getAllShootingsOfBroker(this.props.match.params.id)
                                    .then(res=>{
                                        this.setState({userShootings:res.data.shootings});
                                    })
                                    .catch(e=>{
                                        console.log(e);
                                    });
                                }
                            }

                            window.Hauslife.getHMDsByUserId(this.state.user.id)
                            .then((response)=>{
                                if(response.data.status == 200)
                                {
                                    this.setState({userHMDS:response.data.hmds});
                                }
                            })
                            .catch((err)=>{
                                console.log(err);
                            });
                        });
                        break;
                    case 'createUser':
                        this.setState({
                            user: {
                                id: null,
                                lastname: '',
                                firstname: '',
                                email: '',
                                phone_number: '',
                                role: '',
                                password: null ,
                                skills:[],
                                zones: [],
                                backupZones: []                    
                            },
                            passwordField: '',
                            passwordValidationField: '',
                            loaded: false,
                            dlgShow: true,
                            dlgTitle: 'Hauslife',
                            dlgText: "L’usager a été créé avec succès. Vous pouvez en créer un nouveau ou retourner à la liste."
                        });
                        break;
                    case 'putUserData':
                        this.setState({
                            dlgShow: true,
                            dlgTitle: 'Hauslife',
                            dlgText: "La sauvegarde effectué avec succès."
                        });
                        break;                        
                }
            })
            .catch((e) => {
                let errObj = { dlgShow: true };
 
                if(e.response==undefined) {
                    errObj.dlgTitle = 'Exception';
                    errObj.dlgText = e.toString();
                    this.setState(errObj);
                    return;
                }
                switch (e.response.status) {
                    case 409:
                        errObj.dlgTitle = 'Une erreur est survenue.';
                        errObj.dlgText = "L'usager existe déjà.";
                        break;
                    default:
                        errObj.dlgTitle = e.response.statusText;
                        errObj.dlgText = e.response.data.message;
                }
                this.setState(errObj);
            });
    }

    WelcomeMessage=()=>{
        if(this.state.user.id==null){
            return "Création d'un nouveau utilisateur.";
        } 
        return 'Edition : ( ' + this.state.user.id+' ) ' + this.state.user.firstname+' '+this.state.user.lastname; 
    }
    
    handleSubmit = event => {         
        event.preventDefault();
        // Par defaut le service est en mode edition
        let service = 'putUserData';
 
        let data = {};
 
        for (var attr in this.state.user) {            
            if (this.state.user.hasOwnProperty(attr)){
                data[attr] = this.state.user[attr];
                if(typeof data[attr] == 'string'){
                    data[attr] = data[attr].trim();
                }
            } 
        }
        data.zenDesk=this.state.ZendDesKUser;
        // L'écran est en mode ajout d'utilisateur (state.user.id est null)
        if(this.state.user.id==null) {
            service = 'createUser';
            delete data.id;
           
            if (this.state.passwordField.length < 6) {
                this.setState({
                    dlgShow: true,
                    dlgTitle: 'Une erreur est survenue.',
                    dlgText: 'Le mot de passe doit contenir six caractères.',
                });
                return;
            }
            if (this.state.passwordField != this.state.passwordValidationField) {
                this.setState({
                    dlgShow: true,
                    dlgTitle: 'Une erreur est survenue.',
                    dlgText: 'La validation du mot de passe a échoué.'
                });
                return;
            }
            data.password = this.state.passwordField;
        }
        this.callOnMe(service, data);
    }
    alertClose = () => {
        this.setState({ dlgShow: false });
    }

    convertShootingStateToHuman = (state) =>
    {
        if(state == 0) return "Created";
        if(state == 1) return "Captured";
        if(state == 2) return "Waiting or Uploading";
        if(state == 3) return "Retouching";
        if(state == 4) return "Ready for post";
        if(state == 5) return "Uploaded";
        if(state == 6) return "Confirmed";
        if(state == 7) return "Unconfirmed";
        if(state == 8) return "In-progress";
        if(state == 9) return "Completed";
        if(state == 10) return "Canceled";
        if(state == 11) return "Confirmation required";
        if(state == 12) return "Shooting recommended";
        if(state == 13) return "Shooting abandoned";
    }

    displayRelatedShootings = () =>
    {
        return (
            <div>
                <div>
                    <p><b>Shootings in HMD</b></p>
                    {this.state.userShootings.filter(shooting=>shooting.listing.state==3).map(shooting => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"Shooting ID : "+shooting.id}</p>
                                <p>{this.convertShootingStateToHuman(shooting.state)}</p>
                                <Link style={{color:"#000"}} to={"/viewdelivery/" + shooting.listing.id}>{shooting.listing.id + " | " +shooting.listing.address + (shooting.listing.app ? ", # " + shooting.listing.app + " " : "") + ", " + shooting.listing.city}</Link>
                            </CardContent>
                            <CardMedia
                                style={{width:151}}
                                image={shooting.listing.picture_url}
                            />
                        </Card>
                    ))}
                </div>
                <div>
                    <p><b>Others</b></p>
                    {this.state.userShootings.filter(shooting=>shooting.listing.state!=3).map(shooting => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"Shooting ID : "+shooting.id}</p>
                                <p>{this.convertShootingStateToHuman(shooting.state)}</p>
                                <Link style={{color:"#000"}} to={"/viewdelivery/" + shooting.listing.id}>{shooting.listing.id + " | " +shooting.listing.address + (shooting.listing.app ? ", # " + shooting.listing.app + " " : "") + ", " + shooting.listing.city}</Link>
                            </CardContent>
                            <CardMedia
                                style={{width:151}}
                                image={shooting.listing.picture_url}
                            />
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    unassignHMD = (serial_number) =>
    {
        var yesorno = window.confirm("Are you sure?");

        if(yesorno == true)
        {
            window.Hauslife.unassignHmd(serial_number)
            .then((res)=>{
                if(res.data.status == 200)
                {
                    alert("Unassigned.");
                }
            })
            .catch((err)=>{
                alert(err);
            });
        }
    }

    displayHMDsInformation = () =>
    {
        if(this.state.userHMDS.length > 0)
        {
            return (
                <div>
                    <p><b>HMDS assigned</b></p>
                    {this.state.userHMDS.map(hmd => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"HMD ID : "+hmd.id}</p>
                                <p>{"HMD SERIAL : "+hmd.serial_number}</p>
                                <Button onClick={()=>{this.unassignHMD(hmd.serial_number)}} variant="outlined">UNassign</Button>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            );
        }
        else
        {
            return(<div></div>);
        }
    }

    render() {
        var suspendedCheck= this.state.user.suspended==undefined?false:this.state.user.suspended;

        const { classes } = this.props;

        return (
            <Paper className={classes.root}>

                <AlertDialog show={this.state.dlgShow} didClose={this.alertClose} title={this.state.dlgTitle} text={this.state.dlgText} ></AlertDialog>
               
                {window.Hauslife.userObject.role <= admin_role  &&
                    <Button  variant="outlined" color="inherit" className={classes.button}   onClick={this.props.history.goBack}>Retour</Button>
                }
                <Paper className={classes.paper}>
                    {this.WelcomeMessage()}
                </Paper>
                <form onSubmit={this.handleSubmit} className={classes.container} autoComplete="off"  autoFill="off">
                    <TextField
                        id="firstname"
                        label="Prénom"
                        className={classes.textField}
                        value={this.state.user.firstname}
                        onChange={this.handleChange('firstname')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="lastname"
                        label="Nom"
                        className={classes.textField}
                        value={this.state.user.lastname}
                        onChange={this.handleChange('lastname')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="email"
                        type="email"
                        label="Courriel"

                        className={classes.wideTextField}
                        value={this.state.user.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="phone_number"
                        label="Téléphone"
                        className={classes.textField}
                        value={this.state.user.phone_number}
                        onChange={this.handleChange('phone_number')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    {this.state.user.id==null &&  
                        <TextField
                            id="passwordField"
                            label="Mot de passe"
                            className={classes.textField}
                            value={this.state.passwordField}
                            onChange={this.handleChangePassword('passwordField')}
                            margin="normal"
                            variant="outlined"
                            type="password"
                            required={this.state.passwordRequired}
                        />
                    }
                    {this.state.user.id==null &&
                        <TextField
                            id="passwordValidationField"
                            label="Vérification mot de passe"
                            className={classes.textField}
                            value={this.state.passwordValidationField}
                            onChange={this.handleChangePassword('passwordValidationField')}
                            margin="normal"
                            variant="outlined"
                            type="password"
                            required={this.state.passwordRequired}
                        />
                    } 
                    
                    {window.Hauslife.userObject.role <= admin_role  &&
                      <>
                        <TextField
                            id="role"
                            select
                            label="Role"
                            className={classes.textField}
                            value={this.state.user.role}
                            onChange={this.handleChange('role')}
                            margin="normal"
                            variant="outlined"
                            required>
                            {roles.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        {this.state.user.role == post_prod_tech_role ||  this.state.user.role == prod_tech_role ? (
                            <TextField
                                id="depot_id"
                                select
                                label="Depot"
                                className={classes.wideTextField}
                                value={this.state.user.depot_id}
                                onChange={this.handleChange('depot_id')}
                                margin="normal"
                                variant="outlined"
                                required>
                                {this.state.depots.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.civic_number + ", " + option.street + ", " + option.city}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null }

                        {this.state.user.role == post_prod_tech_role ||  this.state.user.role == prod_tech_role ? (
                            <TextField
                                id="zone_id"
                                select
                                label="Zone Principale"
                                className={classes.wideTextField}
                                value={this.state.user.zone_id}
                                onChange={this.handleChange('zone_id')}
                                margin="normal"
                                variant="outlined"
                                required>
                                {this.state.zones.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.zone_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null }

                        {this.state.user.role == post_prod_tech_role ||  this.state.user.role == prod_tech_role ? (
                            
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="select-multiple-zones">Backup Zones</InputLabel>
                                <Select
                                    multiple                                    
                                    value={this.state.user.backupZones}
                                    onChange={this.handleChange('backupZones')}
                                    input={<Input id="select-multiple-zones" />}>
                                    {this.state.zones.map(item => (
                                    <MenuItem key={item.id} value={item.id} >
                                        {item.zone_name}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null }
                     

                        {this.state.user.role == broker_role ? (
                            <TextField
                                id="broker_code"
                                label="code courtier"
                                className={classes.textField}
                                value={this.state.user.broker_code}
                                onChange={this.handleChange('broker_code')}
                                margin="normal"
                                variant="outlined"  />
                        ) : null }
                    
                        {this.state.user.role == post_prod_tech_role ||  this.state.user.role == prod_tech_role ? (
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="select-multiple">Compétences</InputLabel>
                                <Select
                                    multiple
                                    
                                    value={this.state.user.skills}
                                    onChange={this.handleChange('skills')}
                                    input={<Input id="select-multiple" />}
                                    
                                >
                                    {this.state.userSkills.map(item => (
                                    <MenuItem key={item.id} value={item.id} >
                                        {item.name}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null }

                        {this.state.user.role == broker_role ? (
                            <>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-team">Équipe</InputLabel>
                                    <Select
                                        value={this.state.user.team_id}
                                        onChange={this.handleChange('team_id')}
                                        input={<Input id="select-team" />}
                                        
                                    >
                                        <MenuItem key={null} value={null} >  </MenuItem>
                                        {this.state.teams.map(item => (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.name}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-franchise">Franchise</InputLabel>
                                    <Select
                                        value={this.state.user.franchise_id}
                                        onChange={this.handleChange('franchise_id')}
                                        input={<Input id="select-franchise" />}>
                                        <MenuItem key={null} value={null} ></MenuItem>
                                        {this.state.franchises.map(item => (
                                            <MenuItem key={item.id} value={item.id} >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                         ) : null }


                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.user.gf_clause == true}
                                    onChange={this.handle_gf_clause_change()}
                                    color="primary"
                                />
                            }
                            label="Clause Grand-Père"
                        />

                        {/*<FormControlLabel
                            control={
                                <Checkbox
                                checked={this.state.ZendDesKUser}
                                onChange={this.handleCheckboxChange('ZendDesKUser')}
                                
                                color="primary"
                                />
                            }
                            label="Zen Desk user"
                            />
                            */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={suspendedCheck}
                                    onChange={this.handleSuspended}
                                    color="primary"
                                />
                                }
                            label="Suspendue"
                            />
                        </>
                    }
                    {this.state.user.id==null &&
                        <Button type="submit" variant="outlined" color="inherit" className={classes.button}>Ajouter</Button>
                    }
                    {this.state.user.id!=null &&
                        <Button type="submit" variant="outlined" color="inherit" className={classes.button}>Sauver</Button>
                    }       

                </form>

                {this.state.user.id!=null &&
                    <ChangePassword userId={this.state.user.id}/>
                }

                { this.state.zendDeskUserData!=null &&
                    <Card className={classes.itemCard}>
                        <CardContent>
                            <Typography variant="h6" component="h6"> Usager Zen Desk</Typography>

                            <Typography  color="textSecondary">Nom</Typography>
                            <Typography component="p" className={classes.zdUserInfo}>
                                {this.state.zendDeskUserData.name}
                            </Typography>
                            
                            <Typography  color="textSecondary">Zen Desk User Id</Typography>
                            <Typography component="p" className={classes.zdUserInfo}>
                            {this.state.zendDeskUserData.id}
                            </Typography>

                            <Typography  color="textSecondary">Courriel</Typography>
                            <Typography component="p" className={classes.zdUserInfo}>
                            {this.state.zendDeskUserData.email}
                            </Typography>

                            <Typography  color="textSecondary">Téléphone </Typography>
                            <Typography component="p" className={classes.zdUserInfo}>
                                {this.state.zendDeskUserData.phone}
                            </Typography>

                            <Typography  color="textSecondary">Zend Desk Role</Typography>
                            <Typography component="p" className={classes.zdUserInfo} >
                                {this.state.zendDeskUserData.role}
                            </Typography>
                        </CardContent>
                    </Card>
                }

                {this.displayRelatedShootings()}
                {this.displayHMDsInformation()}
            </Paper>
        );
    }

}


export default withStyles(styles)(EditUser);