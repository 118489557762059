import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import WeekView from './WeekView'
import { month } from 'react-big-calendar/lib/utils/dates'

require('react-big-calendar/lib/css/react-big-calendar.css');

const localizer = momentLocalizer(moment)

function Event({ event }) {

    let EventUIBar = (props) => {
        const {title, body} = props
        return (
            <span>
                <strong>{title}</strong>
                {body}
            </span>
        )
    }

    switch(event.type) {
        case 'availabilities':
            return <EventUIBar 
                title={event.isAdmin ? `${event.availability.user.firstname} ${event.availability.user.lastname}` : null}
                body={event.isAdmin ? "" : " Disponible"} />
        case 'unavailability':
            return <EventUIBar 
                title={`${moment(event.start).format("HH:ss")}`}
                body={event.isAdmin && event.tech && ` ${event.tech.firstname} ${event.tech.lastname}`} />
        case 'shooting':
            return <EventUIBar 
                title={event.techs}
                body={` captures`} />
        case 'new-availability':
            return <EventUIBar 
                body={`Nouvelle disponibilité`} />
        default:
            return null
    }
}

const eventWithStyles = (event, currentView) => {
    switch(event.type) {
        case 'availabilities':
            return {style: {backgroundColor:'green'}}
        case 'unavailability':
            return {style: {backgroundColor:'red',maxWidth: '300px'}}
        case 'shooting':
            return {style: {backgroundColor:'blue', maxWidth: '300px', border: '1px solid black'}}
        default:
            return null
    }
}
  
function EventAgendaView({ event }) {
    switch(event.type) {
        case 'availabilities':
            return (
                <div>
                    <p>{event.tech && `${event.tech.firstname} ${event.tech.lastname}`}</p>
                </div>
            )
        case 'unavailability':
            return (
                <div>
                    <p>{event.tech && `${event.tech.firstname} ${event.tech.lastname}`}</p>
                </div>
            )
        case 'shooting':
            return (
                <div>
                    <p style={{fontSize: '10px'}}>{(event.tech != null) ? `${event.tech.firstname} ${event.tech.lastname}` : 'Unassigned shoot'}</p>
                    <p style={{fontSize: '10px'}}>{event.address}</p>
                </div>
            )
        default:
            return null
    }
}

function EventDayView({event}) {
    switch(event.type) {
        case 'availabilities':
            return (
                <div>
                    <p>{event.tech && `${event.tech.firstname} ${event.tech.lastname}`}</p>
                </div>
            )
        case 'unavailability':
            return (
                <div>
                    <p>{event.tech && `${event.tech.firstname} ${event.tech.lastname}`}</p>
                </div>
            )
        case 'shooting':
            return (
                <div>
                    <p>{event.tech && `${event.tech.firstname} ${event.tech.lastname}` || 'Unassigned shoot'}</p>
                    <p>{event.address}</p>
                </div>
            )
        case 'new-unavailability':
            return (
                <div>
                    <p>New Unavailability</p>
                </div>
            )
        default:
            return null
    }
}

const HausCalendar = (props) => {

    let {events, onNavigate, onViewChange, onCreate, currentView, currentDate, onDateChange, onAvailabilityClick, onUnavailabilityClick, onShootingClick, isAdmin} = props

    if(currentDate == undefined) {
        currentDate = moment().toDate()
    }

    const handleViewChange = (view) => {
        onViewChange(view)
    }

    const onSlotSelected = (slotInfo) => {
        let { start, end, action, slots, ...props} = slotInfo
        if(action == 'select') {
            // create a new unavailability
            onCreate(slots)
        } else if(action == 'click') {
            // take to day view
            handleViewChange('day')
            onDateChange(start)
        }
    }

    const handleEventClick = (event, e) => {
        if(event.type == 'availabilities') {
            onAvailabilityClick(event)
        } else if(event.type == 'unavailability') {
            onUnavailabilityClick(event)
        } else if(event.type == 'shooting') {
            onShootingClick(event)
        }
    }

    let eventsWithProps = events.map(eve => ({...eve, ...{'isAdmin': isAdmin}}))

    return <Calendar
        localizer={localizer}
        events={eventsWithProps}
        startAccessor="start"
        endAccessor="end"
        style={{ width: '100%', height: 700 }}
        popup={true}
        eventPropGetter={(props) => eventWithStyles({...props}, currentView)}
        views={{
            month: true,
            week: WeekView,
            day: true
        }}
        components={{
            month: {
                event: Event
            },
            week: {
                event: EventAgendaView
            },
            day: {
                event: EventDayView
            }
        }}
        onNavigate={onNavigate}
        onView={handleViewChange}
        onSelectSlot={onSlotSelected}
        onSelectEvent={handleEventClick}
        selectable={true}
        view={currentView}
        date={currentDate}
        min={moment().set('hour', 7).set('minute', 30).toDate()}
        max={moment().set('hour', 18).set('minute', 30).toDate()}
    />
}

HausCalendar.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        allDay: PropTypes.bool,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date)
    }))
}

export default HausCalendar