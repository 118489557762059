import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import PleaseWait from './PleaseWait.js'
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { Prompt } from 'react-router-dom'

import * as moment from 'moment';
import 'moment/locale/fr-ca';

var _ = require('lodash');

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    innerGrid: {
        margin: 0,
        padding: 15
    },
    dateField: {
        marginTop:20,
        marginBottom:20,
        display:'flex'
    }
});

/**
 * Class used to view a single ticket
 */
class TechRemoteInjector extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            remote_cards: [],
            is_uploading: false,
            filesToUpload:[],
            fileProgresses:{},
            fileUploadErrors:{},
            is_reloading_files: false,
            files_on_server:[],
            startDate: moment().format('YYYY-MM-DD'),
        };
    }

    componentWillMount() {
        this.reloadFilesOnServer();
    }

    componentWillUnmount() { 
        window.onbeforeunload = null;
    }

    componentDidUpdate = () => {
        if (this.state.is_uploading) {
          window.onbeforeunload = () => true
        } else {
          window.onbeforeunload = undefined
        }
      }

    fileChange = (event) => {
        //application/x-tar
        //application/x-zip-compressed
        //console.log(event.target.files);
        this.setState({filesToUpload:[...this.state.filesToUpload,...event.target.files]});
    }

    fileUpload = (file, uploadURL) => {
        return new Promise((resolve, reject)=>{

            const xhr = new XMLHttpRequest();
            
            let fileName = file.name;
    
            xhr.upload.addEventListener("progress", (e) => {
                  if (e.lengthComputable) {
                    const percentage = Math.round((e.loaded * 100) / e.total);
                    let tempNew = {};
                    tempNew[fileName] = {done:false, percent:percentage};
                    this.setState({fileProgresses:{...this.state.fileProgresses, ...tempNew}});
                  }
                }, false);
            
            xhr.upload.addEventListener("load", (e) => {
                    let tempNew = {};
                    tempNew[fileName] = {done:true, percent:100};
                    this.setState({fileProgresses:{...this.state.fileProgresses, ...tempNew}});
                }, false);

            xhr.addEventListener("load", (e) => {
                if(xhr.status != 200)
                {
                    //console.log(xhr.responseText);
                    reject(new Error("Problem uploading file."));
                }
                else
                {
                    resolve();
                }
            });
    
            xhr.open("PUT", uploadURL);
    
            xhr.setRequestHeader("content-type", file.type);
            //xhr.overrideMimeType(file.type);
            
            xhr.onerror = function (error) {
                console.log(error);
                reject(error);
            }

            xhr.send(file);
        });
    }

    startTheInjection = () => {
        this.setState({is_uploading:true}, () => {

            let uploadPromises = [];

            this.state.filesToUpload.forEach((file)=>{
                if(this.getFileProgress(file.name) == 0)
                {
                    let filename = file.name;
                    //isZip
                    // let isZip = file.type == "application/x-tar" ? false : true;
    
                    var thePromise = window.Hauslife.getUploadURL(filename, file.type)
                    .then((result) => {
                        let googleSpecialUploadLink = result.data.url;
    
                        return this.fileUpload(file, googleSpecialUploadLink);
                    })
                    .then(()=>{
                        //console.log(filename + " uploaded.");
                        return window.Hauslife.rij_uploadedFile(filename);
                    })
                    .then(() => {
                        return window.Hauslife.rij_uploadedFileStatus(filename);
                    })
                    .then(()=>{
                        this.reloadFilesOnServer();
                    })
                    .catch((e) => {
                        //console.log(filename, e);
                        let tempNew = {};
                        tempNew[filename] = e;
                        this.setState({fileUploadErrors:{...this.state.fileUploadErrors, ...tempNew}})
                    });

                    uploadPromises.push(thePromise);
                }
            });

            Promise.allSettled(uploadPromises)
            .then(res=>{
                this.setState({is_uploading:false});
                //console.log("all uploaded.");
            })
            .catch(e=>{
                console.log(e);
                this.setState({is_uploading:false});
            });
        });
    }

    getFileProgress = (name) => {
        if(this.state.fileProgresses[name] != undefined)
        {
            return this.state.fileProgresses[name].percent;
        }

        return 0;
    }

    isFileTransferDone = (name) => {
        if(this.state.fileProgresses[name] != undefined)
        {
            return this.state.fileProgresses[name].done;
        }

        return false;
    }

    doTransferGeneratedError = (name) => {
        if(this.state.fileUploadErrors[name] != undefined)
        {
            return this.state.fileUploadErrors[name];
        }

        return null;
    }

    convertSizeToHuman = (size) => {

        var mb = (size / 1024) / 1024;

        if(mb > 1000)
        {
            return (mb / 1024).toFixed(2) + " GB";
        }
        
        return mb.toFixed(2) + " MB";
    }

    removeFileFromUploadList = (name) =>
    {
        let filesCopy = [...this.state.filesToUpload];

        for(var i = filesCopy.length - 1; i >= 0; i--)
        {
            if(filesCopy[i].name == name)
            {
                filesCopy.splice(i, 1);
            }
        }

        this.setState({filesToUpload:filesCopy});
    }

    reloadFilesOnServer = () =>
    {
        this.setState({is_reloading_files:true},()=>{
        window.Hauslife.getFilesForRemoteInjection(moment(this.state.startDate).format('DD-MM-YYYY'))
            .then((res) => {
                this.setState({ files_on_server: res.data.files, is_reloading_files: false });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ is_reloading_files: false });
            });
        });
    }

    handleDateChange = (event) => {
        this.setState({ startDate: event.target.value }, () => {
            this.reloadFilesOnServer();
        });
    }

    drawRemoteFile = (file) => {
        const updateTime = moment(file.updated);
        const deletionTime = moment(file.updated).add(90,"days");
        return(
            <>
                <Prompt
                    when={this.state.is_uploading}
                    message='Transfert en cours, êtes-vous certain de vouloir quitter?'
                />
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Icon>description</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={file.name}
                        secondary={
                            <>
                                <p>{this.humanFileSize(file.size)}</p>
                                <p title={updateTime.toLocaleString()}>{"Updated : " + updateTime.fromNow()}</p>
                                <p title={deletionTime.toLocaleString()}>{"Will be deleted in " + moment.duration(deletionTime.diff(moment())).humanize()}</p>
                                <Button onClick={()=>this.downloadFile(file.name)}>Download</Button>
                            </>
                            
                        }
                    />
                </ListItem>
                
            </>
        );
    }

    humanFileSize = (size) => {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    };

    render() {
        const { classes, match, intl } = this.props;

        return (
            <Grid container>
               
                <Grid className={classes.innerGrid} xs={12} sm={6}>
                    <Typography variant="h5" component="h5" style={{marginBottom:'20px'}}> Remote Injector </Typography>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Add files
                        <input
                            type="file"
                            accept={"application/x-tar, application/x-zip-compressed, application/zip"}
                            onChange={this.fileChange}
                            style={{ display: "none" }}
                            multiple
                        />
                    </Button>
                    <div style={{marginTop:10}}>
                        {this.state.filesToUpload.length > 0 &&
                            this.state.filesToUpload.map((file)=>
                                <Card style={{float:"left",minWidth:200, padding:15, margin:10, backgroundColor:this.isFileTransferDone(file.name) ? this.doTransferGeneratedError(file.name) != null ? "#ffe5e3" : "#18a344": "#fbfbfb"}}>
                                    <CardContent>
                                        {this.doTransferGeneratedError(file.name) != null &&
                                            <SnackbarContent
                                                style={{backgroundColor:"darkred",marginBottom:10}}
                                                message={"Error while sending file to the cloud."}
                                            />
                                        }
                                        <p><b>{file.name}</b></p>
                                        <p>{this.convertSizeToHuman(file.size)}</p>
                                        {this.getFileProgress(file.name) > 0 && <LinearProgress style={{marginTop:5}} variant="determinate" value={this.getFileProgress(file.name)} />}
                                        {this.doTransferGeneratedError(file.name) != null && <p>{this.doTransferGeneratedError(file.name).message}</p>}
                                    </CardContent>
                                    <CardActions>
                                        <Button 
                                            disabled={this.doTransferGeneratedError(file.name) != null}
                                            onClick={()=>this.removeFileFromUploadList(file.name)} size="small">
                                            Remove from list
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        }
                    </div>
                    <div style={{marginTop:10, clear:"both"}}>
                        <Button color="primary" disabled={this.state.filesToUpload.length == 0 || this.state.is_uploading == true} onClick={this.startTheInjection} variant="contained">Remote Inject</Button>
                    </div>
                </Grid>
                
                <Grid className={classes.innerGrid} xs={12} sm={6}>
                    {this.state.is_reloading_files === true ?
                    (
                        <PleaseWait />
                    )
                    :
                    (
                        <>
                            <b style={{marginTop:'10px'}}>Files in the cloud</b>
                            <TextField
                                id="date"
                                label="Date "
                                type="date"
                                disabled={this.state.is_reloading_files}
                                value={this.state.startDate}
                                className={[classes.textField, classes.dateField]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleDateChange}
                            />
                            <List>
                                {this.state.files_on_server.map(file => (
                                    this.drawRemoteFile(file)
                                ))}
                            </List>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}

TechRemoteInjector.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles)(TechRemoteInjector)));
