
import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PleaseWait from './PleaseWait.js'
import * as moment from 'moment';
import { VictoryBar, VictoryChart ,VictoryLine,VictoryAxis} from 'victory';

class AnalyticVisiteGraph extends React.Component {
    constructor(props) {
        super(props);
        this.scrollHeight=0;

        this.state = {
            error:false,
            loaded: false,
            tabs:'data'
        };

    }

    componentWillMount() {
  //getSuperAnalyticsVisiteData
        console.log('Graphic search :',this.props.query);
         
        window.Hauslife[this.props.route](this.props.query )
            .then((res) => {

                console.log('VISITE DATA',res.data.data);
                 
                var data = res.data.data.map( this.props.dataTrx ) ;

                console.log('data clean', data);
                this.setState({loaded:true,error:false,data:data});

            })
            .catch((e) => {
                this.setState({loaded:true,error:true,list:[]});
                console.log(e);
            });

    }
 

    render(){

        return (

                <div style={{backgroundColor:''}}>  
                    { this.state.loaded==false &&
                        <PleaseWait />
                    }
                    <span style={{display:"table",margin:'auto',fontSize:30,marginTop:5,fontWeight:'bold' }}>{this.props.title}</span>
                    
                        { this.state.loaded==true &&
                            <>
                                <VictoryChart  domainPadding={20}>

                                    <VictoryBar 
                                                interpolation="linear"
                                                data={this.state.data}
                                                x={this.props.x} y={this.props.y} 
                                                />
                                </VictoryChart>

                                <span style={{display:"table",margin:'auto',fontSize:30,marginTop:-50,fontWeight:'bold' }}>{this.props.bottomLabel}</span>
                            </>
                        }
                   
                </div>

        );
    }

}


            
export default  AnalyticVisiteGraph;