import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { injectIntl, intl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import withWidth from '@material-ui/core/withWidth';

import CircularProgress from '@material-ui/core/CircularProgress';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CloseIcon from '@material-ui/icons/Close';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Divider from '@material-ui/core/Divider';
import TablePagination from '@material-ui/core/TablePagination';

import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';

import Lightbox from 'react-image-lightbox';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    root: {
    },
    cell: {
        padding: 5
    },
    innerGrid: {
        margin: theme.spacing.unit
    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    }
});

const rangeoptions = [{ label: "", value: 24 }];

/**
 * Class used to display tickets owned by the user
 */
class MyDeliveries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            itemsPerPage: 15,
            activePage: 0,
            selectedShooting: null,
            isLightBoxOpen: false,
            photoIndex: 0,
            displayedPictres: [],
        };
    }

    componentWillMount() {

        if(window.Hauslife.userObject.role == 10)
        {
            window.Hauslife.getSocialMediasListings()
                .then((result) => {
                    this.setState({ 
                        loading: false,
                        rows: result.data.listings
                    });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                });
        } 
        else if(window.Hauslife.userObject.role == 4 || window.Hauslife.userObject.role == 5) 
        {
            window.Hauslife.getShootingsWithDeliveriesForTech()
                .then((result) => {
                    this.setState({ 
                        loading: false,
                        rows: result.data.shootings
                    });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                });
        }
        else
        {
            window.Hauslife.getMyListings()
                .then((result) => {
                    this.setState({ 
                        loading: false,
                        rows: result.data.listings
                    });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                });
        }
    }

    handleDeliveryClick = (shooting) => {
        this.setState({
            selectedShooting: shooting
        })
    };

    handlePaginationChange = (e, activePage) => {console.log(activePage);this.setState({ activePage:activePage })};

    handleChangeRowsPerPage = (e) => this.setState({ itemsPerPage:e.target.value, activePage:0 })

    handleClickOpen = (pictures, index) => {
        let apictures = [];

        for (var i = 0; i < pictures.length; i++) {
            apictures.push(pictures[i].distrib_url);
        }

        this.setState({ photoIndex: index, displayedPictres: apictures, isLightBoxOpen: true });
    };

    RenderForTech = () => {

        let momentofopen = "?"+moment().format('MMMM-DD-YYYY-h-mm');

        return (
            <div style={{flexGrow: 1, flexDirection: 'column'}}>
                {(this.state.loading == true) ? 
                    (<PleaseWait />) 
                    : 
                    (<Grid container spacing={3} style={{ marginTop: 15 }}>
                        <Grid item xs={6}>
                        {this.state.rows.length > 0 ? (
                            <Paper>
                                <TablePagination
                                    rowsPerPageOptions={[15, 25, 50, 100, 1000, 10000]}
                                    component="div"
                                    count={this.state.rows.length}
                                    rowsPerPage={this.state.itemsPerPage}
                                    page={this.state.activePage}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handlePaginationChange}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                                <List>
                                    {this.state.rows.slice((this.state.activePage)*this.state.itemsPerPage, ((this.state.activePage)*this.state.itemsPerPage) + this.state.itemsPerPage).map((row,i) => 
                                        <>
                                            <ListItem style={{ cursor: "pointer" }} button key={row.id} onClick={() => this.handleDeliveryClick(row)}>
                                                <ListItemAvatar>
                                                    <Avatar src={row.listing.picture_url} />
                                                </ListItemAvatar>
                                                <ListItemText primary={(row.listing.app ? row.listing.app + "-" : "") + row.listing.address + ", " + row.listing.city} secondary={moment(row.listing.updated_at).format("l LTS")} />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                </List>
                                    
                                <TablePagination
                                    rowsPerPageOptions={[15, 25, 50, 100, 1000, 10000]}
                                    component="div"
                                    count={this.state.rows.length}
                                    rowsPerPage={this.state.itemsPerPage}
                                    page={this.state.activePage}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handlePaginationChange}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        
                            ) :
                                (
                                    <Typography style={{ padding: 15 }} component="h3" variant="h3" align={"center"}><FormattedMessage id="deliveries.no_deliveries" /></Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={{marginLeft: '2px'}}>
                                {
                                    this.state.selectedShooting &&
                                    <>
                                        <div style={{
                                            display: "flex",
                                            marginBottom: 15,
                                            justifyContent: "space-between",
                                            flexWrap: "wrap",
                                            width: "100%"
                                        }}>
                                            <TextField style={{ display: "flex", flexGrow: 4, marginRight:10 }} label={"Sharable link"} variant="outlined" value={"https://viewer.hausvalet.ca/visit/"+this.state.selectedShooting.listing.id} />
                                            <Button style={{ display: "flex", flexGrow: 1 }} target={"_blank"} href={"https://viewer.hausvalet.ca/visit/"+this.state.selectedShooting.listing.id} variant="outlined" color="primary">
                                                Virtual Visit
                                            </Button>
                                        </div>
                                        {
                                            this.state.isLightBoxOpen &&
                                            <Lightbox
                                                mainSrc={this.state.displayedPictres[this.state.photoIndex]}
                                                nextSrc={this.state.displayedPictres[(this.state.photoIndex + 1) % this.state.displayedPictres.length]}
                                                prevSrc={this.state.displayedPictres[(this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length]}
                                                onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        photoIndex: (this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        photoIndex: (this.state.photoIndex + 1) % this.state.displayedPictres.length,
                                                    })
                                                }
                                            />
                                        }
                                        <GridList >
                                        {
                                            this.state.selectedShooting.pictures.filter((pic)=>pic.thumbnail_url!=null).map((picture, i, allPics) => {
                                                return (
                                                    <GridListTile key={picture.id} cols={1} rows={1}>
                                                        <img 
                                                            style={{ cursor: "pointer", filter: picture.public ? "":"grayscale(1)" }} 
                                                            src={picture.thumbnail_url+momentofopen} 
                                                            onClick={() => { this.handleClickOpen(allPics, i); }}
                                                        />
                                                    </GridListTile>
                                                );
                                            })
                                        }
                                        </GridList>
                                    </>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                )
                }
            </div>
        )
    }

    render() {
        const { classes, intl, fullScreen, width, role } = this.props;

        if(role && ['tech_capture', 'tech_postprod'].indexOf(role) != -1) {
            return this.RenderForTech()
        }


        return (
            <div>
                {(this.state.loading == true) ? 
                    (<PleaseWait />) 
                    : 
                    (<Grid>
                        <Grid style={{ marginTop: 15 }} xs={12}>
                        {this.state.rows.length > 0 ? (
                            <Paper>
                                <TablePagination
                                    rowsPerPageOptions={[15, 25, 50, 100, 1000, 10000]}
                                    component="div"
                                    count={this.state.rows.length}
                                    rowsPerPage={this.state.itemsPerPage}
                                    page={this.state.activePage}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handlePaginationChange}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                                <List>
                                    {this.state.rows.slice((this.state.activePage)*this.state.itemsPerPage, ((this.state.activePage)*this.state.itemsPerPage) + this.state.itemsPerPage).map((row,i) => 
                                        window.Hauslife.userObject.role == 10 ? (
                                            <>
                                                <ListItem target={"_blank"} href={"https://livraison.hausvalet.ca/delivery/"+row.distribution_token} component={Button} style={{ cursor: "pointer" }} button key={row.id}>
                                                    <ListItemAvatar>
                                                        <Avatar src={row.picture_url} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={(row.app ? row.app + "-" : "") + row.address + ", " + row.city} secondary={moment(row.updated_at).format("l LTS")} />
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ) : (
                                            <>
                                                <ListItem to={"/viewdelivery/" + row.id} component={Link} style={{ cursor: "pointer" }} button key={row.id}>
                                                    <ListItemAvatar>
                                                        <Avatar src={row.picture_url} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={(row.app ? row.app + "-" : "") + row.address + ", " + row.city} secondary={moment(row.updated_at).format("l LTS")} />
                                                </ListItem>
                                                <Divider />
                                            </>
                                        )
                                    )}
                                </List>
                                    
                                <TablePagination
                                    rowsPerPageOptions={[15, 25, 50, 100, 1000, 10000]}
                                    component="div"
                                    count={this.state.rows.length}
                                    rowsPerPage={this.state.itemsPerPage}
                                    page={this.state.activePage}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handlePaginationChange}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        
                            ) :
                                (
                                    <Typography style={{ padding: 15 }} component="h3" variant="h3" align={"center"}><FormattedMessage id="deliveries.no_deliveries" /></Typography>
                                )
                            }
                        </Grid>
                    </Grid>
                )
                }
            </div>
        );
    }
}


/*

                            {this.state.rows.length > 0 ? (
                                <div>
                                    <div style={{ display: "flex", marginTop: 15, marginBottom: 15, justifyContent: "space-between" }}>
                                        <h3><FormattedMessage id="deliveries.title" /></h3>
                                    </div>
                                    <List>
                                        {this.state.rows.map(row => {
                                                return !row.resolved ? (
                                                    <ListItem to={"/viewdelivery/" + row.id} component={Link} style={{ cursor: "pointer" }} button key={row.id}>
                                                        <ListItemAvatar>
                                                            <Avatar src={row.picture_url} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={(row.app ? row.app + "-" : "") + row.address + ", " + row.city} secondary={moment(row.updated_at).format("l LTS")} />
                                                    </ListItem>
                                                ) : (null);
                                        })}
                                    </List>
                                </div>

*/

MyDeliveries.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withMobileDialog()(injectIntl(withRouter(withStyles(styles)(MyDeliveries)))));
