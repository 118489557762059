import React, { useContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'


// store
import { ZoneContext } from "./store"
import actions from "./store/actions"

const styles = {
	mainContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    }
};

const ShootingDialog = ({shootings, isOpen, onClose}) => {
	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Assigned Shoots</DialogTitle>
			<List style={styles.listContainer}>
        		{
        			shootings && shootings.map((shoot, idx) => (
        				<Card 
        					key={idx}
        					draggable
        					onDragOver={({...param}) => console.log("drag over with param", param)}
                            onDragEnter={({...param}) => console.log("drag Enter with param", param)}
                            onDragLeave={({...param}) => console.log("drag leave with param", param)}
                            onDrop={({...param}) => console.log("on drop with param", param)}
                            style={{flex: 1, minWidth: '500px'}}
    					>
        					<CardHeader 
        						title={`Broker Name: ${shoot.broker.firstname} ${shoot.broker.lastname}`}
        					/>
        					<CardContent>
        						<Typography variant="div" color="textSecondary">
						          {`Shooting ID: ${shoot.shooting.id}`}
						        </Typography>
						        <Typography variant="div" color="textSecondary">
						          {`Assigned Tech ID: ${shoot.shooting.tech_id}`}
						        </Typography>
        					</CardContent>
        				</Card>
        			))	
    			}
    		</List>
		</Dialog>	
	)
}

const AssignTechDialog = ({techs, shoot, onAssign, isOpen}) => {
	return (
		<Dialog open={isOpen} onClose={() => onAssign(null)}>
			<DialogTitle>Photographers</DialogTitle>
			<List style={styles.listContainer}>
        		{
        			techs && techs.map((tech, idx) => (
        				<Card 
        					key={idx}
                            style={{flex: 1, minWidth: '500px'}}
                            onClick={() => {
                            	onAssign(tech.id)	
                        	}}
    					>
        					<CardHeader 
        						title={`Name: ${tech.firstname} ${tech.lastname}`}
        					/>
        					<CardContent>
        						<Typography variant="div" color="textSecondary">
						          {`Tech Address: ${tech.depot.civic_number}, ${tech.depot.street}, ${tech.depot.city}`}
						        </Typography>
						        <Typography variant="div" color="textSecondary">
						          {`Tech Id: ${tech.id}`}
						        </Typography>
        					</CardContent>
        				</Card>
        			))	
    			}
    		</List>
		</Dialog>	
	)
}

export default (props) => {

	const {state, dispatch} = useContext(ZoneContext)

	const { currentZones, activeZone, currentUserScreen, shootings, techs, selectedZones, unassignedShoot, techShootMap } = state

	const [zone, setZone] = useState(null)
	const [loading, setLoading] = useState(false)

	const [startDate, setStartDate] = React.useState(new Date());
	const [endDate, setEndDate] = React.useState(new Date());
	
	const [selectedTech, selectTech] = useState(null)
	const [selectedShoot, selectShoot] = useState(null)

	const fetchShootings = () => {
		if(zone == null) {
			return;
		}
		let zone_id = zone.id;
		let start_date = moment(startDate).format('YYYY-MM-DD')
		let end_date = moment(endDate).format('YYYY-MM-DD')
		setLoading(true)
		
		window.Hauslife.getShootingsAndDepotsForZone(zone_id, start_date, end_date).then(result => {
			// let markers = shootings.data.data.map(shoot => shoot.listing).filter(({lat, lng}) => (lat != null || lng != null))
			dispatch({type: actions.FETCHED_SHOOTINGS, data: result.shootings})
			dispatch({type: actions.FETCHED_TECHS, data: result.techs})
			dispatch({type: actions.MAP_SHOOTINGS_WITH_TECH})
			setLoading(false)
		})
	}

	const getAssignedShootsForTech = tech_id => {
		let child = techShootMap.hasOwnProperty(tech_id)
			? techShootMap[tech_id].length
			: 0

		return (
			<Link 
				component="h4" 
				onClick={() => {
					if(child != 0) {
						selectTech(tech_id)
					}	
				}}
			>
				{child}
			</Link>	
		)
	}

	const handleShootAssign = (tech_id) => {

		window.Hauslife.assignProdShooting(false, false, selectedShoot.shooting.id, tech_id)
			.then(res => {
				if(res.data.success == true) {
					// shoot assigned successfully
					dispatch({type: actions.SHOOT_ASSIGNED_SUCCESS, data: {tech_id: tech_id, shoot: selectedShoot}})
				} else {
					// assign failed
				}
				selectShoot(null)
			})
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', flex: 1, marginTop: '10px'}}>
			<div style={{display: 'flex', flexDirection: 'row', flex: 0, justifyContent: 'flex-start', alignItems: 'center', margin: '10px 10px 30px 100px'}}>
				<div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'}}>
					<InputLabel id="start-date">Start Date:</InputLabel>
					<DatePicker 
						selected={startDate}
						onChange={date => setStartDate(date)}
						customInput={<TextField variant="outlined" />}
					/>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'}}>
					<InputLabel id="end-date">End Date:</InputLabel>
					<DatePicker 
						selected={endDate}
						onChange={date => setEndDate(date)}
						customInput={<TextField variant="outlined" />}
					/>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start'}}>
					<InputLabel id="multiple-zone-select">Zone</InputLabel>
					<Select
						label="multiple-zone-select"
						value={zone}
						onChange={e => {
							setZone(e.target.value)
							e.preventDefault()
						}}
						input={<Input variant="outlined" style={{minWidth: '300px'}} />}
						renderValue={selected => (
				            <div>
				            	{selected.zone_name}
				            </div>
			          	)}
					>
					{
						currentZones.map(zone => (
							<MenuItem key={zone.id} value={zone}>
								{zone.zone_name}
							</MenuItem>
						))	
					}
					</Select>
				</div>
				<Button 
					variant="contained" 
					color="primary"
					onClick={fetchShootings}
					style={{flex: 1}}
				>{"Apply"}</Button>
			</div>
			<div style={styles.mainContainer}>

				<div style={styles.listContainer}>
	        		<h3>Photographers</h3>
	        		<List style={styles.listContainer}>
	        		{
	        			techs && techs.map(tech => (
	        				<Card 
	        					key={tech.id}
	        					draggable
	        					onDragOver={({...param}) => console.log("drag over with param", param)}
                                onDragEnter={({...param}) => console.log("drag Enter with param", param)}
                                onDragLeave={({...param}) => console.log("drag leave with param", param)}
                                onDrop={({...param}) => console.log("on drop with param", param)}
                                style={{flex: 1, minWidth: '500px'}}
        					>
	        					<CardHeader 
	        						title={`Name: ${tech.firstname} ${tech.lastname}`}
	        					/>
	        					<CardContent>
	        						<Typography variant="div" color="textSecondary">
							          {`Tech Address: ${tech.depot.civic_number}, ${tech.depot.street}, ${tech.depot.city}`}
							        </Typography>
							        <Typography variant="div" color="textSecondary">
							          {`Tech Id: ${tech.id}`}
							        </Typography>
							        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
							        	<Typography variant="div" color="textSecondary" style={{marginRight: '10px'}}>
								          {`Assigned Shoots: `}
								        </Typography>
								        {
								        	getAssignedShootsForTech(tech.id)	
							        	}
							        </div>
	        					</CardContent>
	        				</Card>
	        			))	
	    			}
	        		</List>
	        	</div>
	        	{
	        		selectedTech && <ShootingDialog onClose={() => selectTech(null)} isOpen={selectedTech != null} shootings={techShootMap[selectedTech]} />	
        		}
	        	<div style={styles.listContainer}>
	        		<h3>Shootings</h3>
	        		<List style={styles.listContainer}>
	        		{
	        			unassignedShoot
	        				.sort((a, b) => 
	        					moment(a.shooting.scheduleddate).isAfter(moment(b.shooting.scheduleddate))
	        					? -1 : 1
        					).map((shoot, idx) => (
		        				<Card 
		        					key={idx}
		        					draggable
		        					onDragOver={({...param}) => console.log("drag over with param", param)}
	                                onDragEnter={({...param}) => console.log("drag Enter with param", param)}
	                                onDragLeave={({...param}) => console.log("drag leave with param", param)}
	                                onDrop={({...param}) => console.log("on drop with param", param)}
	                                style={{flex: 1, minWidth: '500px'}}
	        					>
		        					<CardHeader 
		        						title={`Broker Name: ${shoot.broker.firstname} ${shoot.broker.lastname}`}
		        						subheader={
		        							<div>
		        								<Typography variant="div" color="textSecondary">
										          {`${shoot.address}, ${shoot.city}`}
										        </Typography>
										        <Typography variant="div" color="textSecondary">
										          {`${moment(shoot.shooting.scheduleddate).format("MMMM Do YYYY hh:mm:ss")}`}
										        </Typography>
		        							</div>	
	        							}
		        					/>
		        					<CardContent>
		        						<Typography variant="div" color="textSecondary">
								          {`Listing ID: ${shoot.id}`}
								        </Typography>
		        						<Typography variant="div" color="textSecondary">
								          {`Shooting ID: ${shoot.shooting.id}`}
								        </Typography>
		        					</CardContent>
		        					<CardActions>
		        						<Button
								        	variant="contained" 
											color="primary"
								        	onClick={() => {
								        		selectShoot(shoot)
							        		}}
							        	>
								        	Assign Tech
								        </Button>
		        					</CardActions>
		        				</Card>
	        				)
        				)	
	    			}
	        		</List>
	        	</div>
	        	{
	        		selectedShoot && <AssignTechDialog isOpen={selectedShoot != null} onAssign={handleShootAssign} techs={techs} shoot={selectedShoot} />
        		}
			</div>
		</div>
	)
}