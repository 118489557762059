import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import ListingEdit from './ListingEdit';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

var _ = require('lodash');

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'clip',
        fontSize: 23,
    },
    table: {
        minWidth: 700,

    },
    tableRow: {
        cursor: 'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    card: {
        marginBottom: 20
    },
    itemCard: {
        marginBottom: 10,
        backgroundColor: '#eeeeee'
    }
});

const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.markers)
    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: 45.6035, lng: -73.5107 }}
        >
            <MarkerClusterer
                averageCenter
                enableRetinaIcons
                gridSize={60}
            >
                {
                    props.markers.map((marker, index) => (
                        <Marker key={index} position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }} />
                    ))    
                }
            </MarkerClusterer>
          </GoogleMap>    
    )
}))

class ListingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loaded: false,
            userId: null,
            selectedRole: -1,
            search: "",
            list: [],
            count: 0,
            dlgShow: false,
            showMap: false,
            allListings: []
        };
    }

    componentWillMount() {
        window.Hauslife.getListingsList('', true)
            .then((res) => {
                this.setState({ list: res.data.listings, loaded: true, count: res.data.count });
            })
            .catch((e) => {
                console.log(e);
            });

        // window.Hauslife.getListingsListForMap()
        //     .then((res) => {
        //         this.setState({ allListings: res.data.listings });
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });
    }

    handleClick = (event, listing) => {
        this.setState({ dlgShow: true, listing: listing });
    }

    searchChange = (event) => {
        this.setState({ search: event.target.value })
    }

    handleChangeSearch = (event) => {
        if (this.queryTimer) {
            clearTimeout(this.queryTimer);
        }

        this.queryTimer = setTimeout(() => {
            this.setState({ loaded: false });

            window.Hauslife.getListingsList(this.state.search)
                .then((res) => {
                    this.setState({ list: res.data.listings, loaded: true, count: res.data.count });
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);

        this.setState({ search: event.target.value });
    }

    brokerName(listing) {
        if (listing.broker == undefined) return;
        var code = listing.broker.broker_code == null ? '' : ', Broker code : ' + listing.broker.broker_code;
        return listing.broker.firstname + ' ' + listing.broker.lastname + ' (Id: ' + listing.broker.id + code + ')';
    }

    alertClose = (button, data) => {
        if (button == 'cancel') {
            this.setState({ dlgShow: false });
            return;
        }
        this.setState({ list: [], loaded: false });

        window.Hauslife.getListingsList(this.state.search)
            .then((res) => {
                this.setState({
                    list: res.data.listings,
                    dlgShow: false,
                    loaded: true,
                    count: res.data.count
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    mapState(value) {
        switch (value) {
            case 0: return 'created';
            case 1: return 'captured';
            case 2: return 'validated';
            case 3: return 'vr_ready';
            case 4: return 'postponed';
        }
    }

    renderTable(rows) {
        const { classes } = this.props;

        return (

            <Table className={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className="listing-list-cell">Id</TableCell>
                        <TableCell className="listing-list-cell">Adresse</TableCell>

                        <TableCell className="listing-list-cell">Courtier</TableCell>

                        <TableCell className="listing-list-cell">Courtier Id</TableCell>
                        <TableCell className="listing-list-cell">Courtier Code</TableCell>

                        <TableCell className="listing-list-cell">Date Shooting</TableCell>
                        <TableCell className="listing-list-cell">Date Photos</TableCell>
                        <TableCell className="listing-list-cell">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow className={classes.tableRow} key={row.id} onClick={event => this.handleClick(event, row)}>
                            <TableCell className="listing-list-cell">{row.id}</TableCell>
                            <TableCell className="listing-list-cell" component="th" scope="row"><ul className="no-bullet">
                                <li>{row.address}</li>
                                {row.app != null &&
                                    <li>App # {row.app}</li>
                                }
                                <li>{row.city}</li>
                                <li>{row.postalcode}</li>
                            </ul> </TableCell>
                            <TableCell className="listing-list-cell">{this.brokerName(row)}</TableCell>
                            <TableCell className="listing-list-cell">{row.broker_id}</TableCell>
                            <TableCell className="listing-list-cell">{row.broker ? row.broker.broker_code : ''}</TableCell>
                            <TableCell className="listing-list-cell">{row.shootingdate != null ? moment(row.shootingdate).format('LLL') : ''}</TableCell>
                            <TableCell className="listing-list-cell">{row.shootingphoto != null ? moment(row.shootingphoto).format('LLL') : ''}</TableCell>
                            <TableCell className="listing-list-cell" >{this.mapState(row.state)/* moment(row.created_at).format('YYYY-MM-DD') */}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        )
    }

    renderMap(rows) {

        let listings = this.state.list;

        let markers = listings.map(row => ({lat: row.lat, lng: row.lng}))

        return(
            <MyMapComponent
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBlV8_octv2fJn5m6dAPJPn0UozRFm_q8&v=3.exp"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `800px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              markers={markers}
            />
        )
    }

    render() {
        const { classes } = this.props;

        var rows = this.state.list;
        var loaded = this.state.loaded;

        return (

            <Grid style={{ height: "100%", flexGrow: 0, alignContent: "flex-start" }} container>

                {this.state.dlgShow == true &&
                    <ListingEdit style={{ width: '100%' }} show={this.state.dlgShow} didClose={this.alertClose} listing={this.state.listing}></ListingEdit>
                }
                <Grid xs={12} style={{ marginBottom: 20 }}>
                    <Paper style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <TextField
                            label="Search"
                            value={this.state.search}
                            onChange={this.handleChangeSearch}
                            margin="normal"
                            style={{ width: '75%' }}
                        />
                        <Typography style={{ display: 'flex', alignSelf: 'flex-end' }}>
                            Le total de cette Requête : {this.state.count}
                        </Typography>
                    </Paper>
                </Grid>
                {loaded == false &&
                    <PleaseWait />
                }

                {loaded == true &&
                    <Grid xs={12} style={{ alignSelf: "flex-start", flex: 1 }}>
                        <Paper className={styles.root}>

                            <Button variant="contained" color="primary" onClick={() => this.setState({showMap: false})}>
                              List View
                            </Button>
                            <Button variant="contained" style={{marginLeft: 10}} color="secondary" onClick={() => this.setState({showMap: true})}>
                              Map View
                            </Button>

                            {
                                this.state.showMap == false
                                ? this.renderTable(rows)
                                : this.renderMap(rows)
                            }
                        </Paper>
                    </Grid>
                }
            </Grid>

        );
    }
}

export default withRouter(withStyles(styles)(ListingList));