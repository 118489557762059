import React, {useState, useContext, useReducer} from 'react'
import TYPES from './actions'

let zoneFormFields = {
	id: null,
	name: '',
	coordinates: [],
	ref: null
}

let reducer = (state, action) => {
	switch(action.type) {
		case TYPES.FETCHING_ZONES_SUCCESS:
			return {...state, zonesInDB: action.data, currentZones: action.data}
		case TYPES.ADD_NEW_ZONE:
			return {...state, currentUserScreen: userScreens[1], activeZone: {...zoneFormFields, ref: React.createRef()}}
		case TYPES.CANCEL_NEW_ZONE:
			return {...state, currentUserScreen: userScreens[0], activeZone: zoneFormFields}
		case TYPES.SAVE_NEW_ZONE:
			return {...state, currentZones: [...state.currentZones, action.data], activeZone: zoneFormFields, currentUserScreen: userScreens[0]}
		case TYPES.EDIT_ZONE:
			return {...state, activeZone: action.data, currentUserScreen: userScreens[2]}
		case TYPES.EDIT_ZONE_SAVE:
			let currentZones = state.currentZones.map(zone => {
				if(zone.id == action.data.id) {
					zone = action.data;
					zone['hasChanged'] = true
				}
				return zone;
			})
			return {...state, currentZones: currentZones, activeZone: zoneFormFields, currentUserScreen: userScreens[0]}
		case TYPES.FETCHED_SHOOTINGS:
			return {...state, shootings: action.data}
		case TYPES.FETCHED_DEPOTS:
			return {...state, techs: action.data}
		case TYPES.MAP_SHOOTINGS_WITH_TECH:
			let o = {}
			let unassignedShoot = []
			for(let i=0; i<state.shootings.length; i++) {
				const shoot = state.shootings[i];
				let tech_id = null
				if(shoot && shoot.hasOwnProperty('shooting') && shoot.shooting.hasOwnProperty('tech_id')) {
					tech_id = shoot.shooting.tech_id
				}
				
				if(tech_id == null) {
					unassignedShoot.push(shoot)
				} else {
					if(!o.hasOwnProperty(tech_id)) {
						o[tech_id] = []
					}
					o[tech_id].push(shoot)
				}
			}
			return {
				...state,
				techShootMap: o,
				unassignedShoot: unassignedShoot
			}
		case TYPES.SHOOT_ASSIGNED_SUCCESS:
			let { tech_id, shoot } = action.data

			let shootings = state.shootings.map(_shoot => {
				if(shoot.id == _shoot.id) {
					_shoot.shooting.tech_id = tech_id
				}
				return _shoot
			})

			let techShootMap = {
				...state.techShootMap
			}

			if(techShootMap.hasOwnProperty(tech_id)) {
				techShootMap[tech_id].push(shoot)
			} else {
				techShootMap[tech_id] = [shoot]
			}

			return {
				...state,
				shootings: shootings,
				techShootMap: techShootMap,
				unassignedShoot: shootings.filter(shoot => shoot.shooting.tech_id == null)
			}

		case TYPES.TOGGLE_DELETE_ZONE:
			let zones = state.currentZones.map(zone => {
				if(zone.id == action.data.id) {
					if(zone.hasOwnProperty('isRemoved')) {
						zone['isRemoved'] = !zone['isRemoved']	
					} else {
						zone['isRemoved'] = true
					}
				}
				return zone;
			})
			return {...state, currentZones: zones}

		case TYPES.TOGGLE_ZONE_VISIBILITY:
			let _zones = state.currentZones.map(zone => {
				if(zone.id == action.data.id) {
					if(zone.hasOwnProperty('isHidden')) {
						zone['isHidden'] = !zone['isHidden']
					} else {
						zone['isHidden'] = true
					}
				}
				return zone;
			})
			return {...state, currentZones: _zones}

		case TYPES.TOGGLE_ZONE_SELECTION:
			let foundIndex = state.selectedZones.findIndex(zone => zone == action.data);
			let selectedZones = []
			if(foundIndex != -1) {
				// found it. now remove it
				selectedZones = [...state.selectedZones.slice(0, foundIndex), ...state.selectedZones.slice(foundIndex+1)]
			} else {
				// not found. add it
				selectedZones = [...state.selectedZones, action.data]
			}
			return {...state, selectedZones: selectedZones}
		
		case TYPES.ON_SAVE_DB:
			let result = action.data
			let updatedZones = []
			debugger
			result.map(res => {
				let type = Object.keys(res)[0]
				if(type == 'create') {
					updatedZones = state.currentZones.map(zone => {
						if(zone.zone_name == res[type]['zone_name']) {
							zone.id = res[type]['id']
						}
						return zone
					})
				} else if(type == 'delete') {
					let deleted_id = res[type]
					updatedZones = state.currentZones.filter(zone => zone.id != deleted_id)
				} else if(type == 'update') {
					let updated_id = res[type]['id']
					updatedZones = state.currentZones.map(zone => {
						if(zone.id == updated_id) {
							zone.zone_name = res[type]['zone_name']
						}
						return zone
					})
				}
			})

			return {
				...state,
				currentZones: updatedZones
			}
			
		case TYPES.ON_SAVE_DB_FAILURE:
			let currZones = state.currentZones.map(zone => {
				if(zone.hasOwnProperty('isRemoved')) {
					delete zone['isRemoved']
				}
				return zone
			})
			return {
				...state,
				currentZones: currZones
			}

		default:
			return state
	}
}

const userScreens = ["view", "create", "edit"]

const initialState = {
	// the current saved zones. We will use a local copy for efficient updates
	zonesInDB: [],
	// current zones the user is playing with. this will get synced to local storage
	currentZones: [],
	// active zone could represent new zone being created or editing existing zone
	activeZone: zoneFormFields,
	// to maintain user state
	currentUserScreen: userScreens[0],
	// Array of selected zone IDs
	selectedZones: [],
	shootings: [],
	techs: [],
	techShootMap: {},
	unassignedShoot: []
}

export const ZoneContext = React.createContext(initialState);

export default (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<ZoneContext.Provider value={{state, dispatch}}>
			{props.children}
		</ZoneContext.Provider>
	)
}