import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import './admin.css';
class ListingEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open: this.props.show,
			confirm: this.props.confirm,
			address: this.props.listing.address,
			city: this.props.listing.city,
			postalcode: this.props.listing.postalcode,
			app: this.props.listing.app,
			mls: this.props.listing.mls,
			sold: props.listing.sold,
			shooting: [],
			centrisListing: [],
			centrisData: [],
			showCentrisListing: false,
			showCentrisInfo: false,
			date_notarized: '',
			badDate: false,
			notSold: false,
			expired: false,
			badMls: false,
			date_sold: this.props.listing.date_sold,
			badSoldDate: false
		};
	}

	componentWillMount() {
		this.loadData()		
	}

	loadData() {
		this.setState({
			date_notarized: moment(this.props.listing.date_notarized).format('YYYY-MM-DD'),
			sold: this.props.listing.sold,
			date_sold: moment(this.props.listing.date_sold).format('YYYY-MM-DD'),
			expired: this.props.listing.expired
		})

		window.Hauslife.adminListingShootings(this.props.listing.id)
			.then((res) => {
				this.setState({ 
					shooting: res.data.data,
					loaded: true
				});
			})
			.catch((e) => {
				console.log(e);
			});

		if (this.props.listing.broker != undefined && this.props.listing.broker.broker_code != null) {
			window.Hauslife.adminCentrisListingForBroker(this.props.listing.broker.broker_code)
				.then((res) => {
					console.log('centris listing', res.data.result)
					this.setState({ centrisListing: res.data.result });
				})
				.catch((e) => {
					console.log(e);
				});
		}

		if (this.props.listing.mls != null && this.props.listing.mls.length > 0) {
			window.Hauslife.adminCentrisListingForMls(this.props.listing.mls)
				.then((res) => {
					this.setState({
						centrisData: res.data.result,
						showCentrisInfo: (res.data.result.length > 0 ? true : false).length
					});
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}

	handleClose = (button, data) => () => {

		if (button == 'ok') {
			if (this.state.sold === true) {
				var bad = false;
				if (moment(this.state.date_notarized).isValid() == false) {
					this.setState({ badDate: true });
					bad = true;
				} else {
					this.setState({ badDate: false });
				}

				if (this.state.mls == null || this.state.mls.length == 0) {
					this.setState({ 'badMls': true });
					bad = true;
				} else {
					this.setState({ 'badMls': false });
				}
				if (moment(this.state.date_sold).isValid() == false) {
					this.setState({ badSoldDate: true });
					bad = true;
				}

				if (bad == true) {
					return;
				}
			}

			var payLoad = {
				id: this.props.listing.id,
				address: this.state.address,
				city: this.state.city,
				postalcode: this.state.postalcode,
				app: this.state.app,
				mls: this.state.mls,
				sold: this.state.sold,
				date_sold: this.state.date_sold,
				expired: this.state.expired
			};

			if(this.state.sold == true){
				payLoad = {
					...payLoad,
					date_sold: this.state.date_sold,
					date_notarized: this.state.date_notarized
				}
			}

			window.Hauslife.adminUpdateListing(payLoad).then((res) => {
				this.props.didClose(button, data);
			}).catch((e) => {
				console.log(e);
			});

			return;
		}

		this.props.didClose(button, data);
	};

	handleChange = (name, event) => {
		var obj = {};
		obj[name] = event.target.value;
		this.setState(obj);
	}

	handleCheckChange(name) {
		let obj = {};
		obj[name] = !this.state[name];
		this.setState(obj);
	}

	componentWillReceiveProps({ someProp }) {
		this.setState({ ...this.state, someProp })
	}

	mapListingState() {
		switch (this.props.listing.state) {
			case 0:
				return 'Créé';
			case 1:
				return 'Capturé';
			case 2:
				return 'Validé';
			case 3:
				return 'Vr Prêt';
			case 4:
				return 'Remis';
		}
	}

	mapShootingState(value) {
		switch (value) {
			case 0: return 'created';
			case 1: return 'captured';
			case 2: return 'validated';
			case 3: return 'retouch';
			case 4: return 'processed';	
			case 5: return 'uploaded';		
			case 6: return 'confirmed';
			case 7: return 'unconfirmed';
			case 8: return 'inprogress';			
			case 9: return 'completed';
			case 10: return 'canceled';
			case 11: return 'confirmation_required';
			case 12: return 'recommended';
			case 13: return 'abandonned';
			default: return value;
		}
	}

	shootingCompleted(shootingState){
		return shootingState == 1 || shootingState == 3 || shootingState == 4 || shootingState == 5 || shootingState == 9;
	}

	listingVisible(listingState){
		return listingState == 3 && this.props.listing.on_market == true;
	}

	techName(tech, shooting) {
		if (shooting[tech] == undefined) return '';
		return shooting[tech].firstname + ' ' + shooting[tech].lastname;
	}

	tabClick(name) {
		var tabState = {
			showCentrisInfo: false,
			showCentrisListing: false
		};

		tabState[name] = !this.state[name];

		this.setState({
			showCentrisInfo: tabState.showCentrisInfo,
			showCentrisListing: tabState.showCentrisListing
		});
	}

	changeMls(centrisListing) {
		this.setState({
			mls: centrisListing.mls,
			/* address:centrisListing.civic_number_start + ' ' + centrisListing.street,
			postalcode: centrisListing.postalcode,
			app:centrisListing.apartement,
			city:centrisListing.municipality.description */
		});
	}

	centrisBrokerString(row, name) {
		if (row[name] == undefined) {
			return '';
		}

		var b = row[name];
		var str = b.firstname + ' ' + b.lastname + ' ' + b.code;

		var JayEshEx = (<li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}> <span>{b.firstname + ' ' + b.lastname}</span> <span>{b.code}</span></li>)
		return JayEshEx;
	}

	AbandonShooting = (shooting_id) =>
	{
		let doAbandon = window.confirm("Are you sure you want to abandon this shooting?");
		if(doAbandon)
		{
			window.Hauslife.abandonShooting(shooting_id)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
		}
	}

	CancelShooting = (shooting_id) =>
	{
		let CancelReason = window.prompt("Please enter the cancellation reason. (5 characters or more)");
		if(CancelReason && CancelReason.length >= 5)
		{
			window.Hauslife.cancelShooting(shooting_id, CancelReason)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
		}
	}

	GoToInvoice = (shooting) => {
		window.open('https://books.zoho.com/app#/invoices/' + shooting.invoice.invoice_id, '_blank');
	}

	GenerateInvoice = (shooting_id) => {
		window.Hauslife.generateInvoice(shooting_id)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
	}

	numberEqualsAny = (number, checkArray) =>
	{
		if(checkArray == undefined)
		{
			checkArray  = [];
		}

		for(var i = 0; i < checkArray.length; i++)
		{
			if(checkArray[i]===number)
			{
				return true;
			}
		}

		return false;
	}

	render() {
		var broker_code = '';
		var broker_id = '';
		var broker_name = '';
		if (this.props.listing.broker != undefined) {
			broker_code = this.props.listing.broker.broker_code;
			broker_id = this.props.listing.broker_id;
			broker_name = this.props.listing.broker.firstname + ' ' + this.props.listing.broker.lastname;
		}
		return (
			<Dialog
				fullWidth={true}
				disableBackdropClick
				disableEscapeKeyDown
				open={this.props.show}
				onClose={this.handleClose}
				aria-labelledby="{alert}-dialog-title"
				aria-describedby="{alert}-dialog-description"
			>
				<DialogTitle id="{alert}-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>

						<Paper style={{ margin: 5, padding: 5, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex' }} >Listing ID : {this.props.listing.id}</div>

							<div style={{ display: 'flex' }} >Statut : {this.mapListingState()} </div >
						</Paper>

						<Paper style={{ backgroundColor: this.listingVisible(this.props.listing.state) ? "#76cc8d" : "#fff2b8",margin: 5, padding: 5, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
							{"HMD visibility status : " + (this.listingVisible(this.props.listing.state) ? "Visible for all" : "Not visible")}
						</Paper>

						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.expired}
									onChange={(event) => this.handleCheckChange('expired', event)}
									color="primary"/>
							}
							label="Expiré"
						/>

						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 20 }}>
							<div className="greyGrad" style={{ display: 'flex', flexDirection: 'column' }} >
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.sold}
											onChange={(event) => this.handleCheckChange('sold', event)}
											style={{ backgroundColor: this.state.notSold ? 'red' : 'transparent' }}
											color="primary"/>
									}
									label="Vendu"
								/>
								{this.state.sold == true ? (
									<>
										<Typography style={{ display: 'flex', marginTop: 20 }} >
											Date de vente
										</Typography>
										<TextField
											id="date"
											type="date"
											value={this.state.date_sold}
											disabled={!this.state.sold}
											InputProps={{ style: { backgroundColor: (this.state.badSoldDate ? 'salmon' : 'transparent') } }}
											InputLabelProps={{
												shrink: true,
												style: { backgroundColor: 'white' }
											}}
											onChange={(event) => this.handleChange('date_sold', event)}
										/>
										<Typography style={{ display: 'flex', marginTop: 20 }} >
											Date du notaire
										</Typography>
										<TextField
											id="date"
											type="date"
											value={this.state.date_notarized}
											disabled={!this.state.sold}
											InputProps={{ style: { backgroundColor: (this.state.badDate ? 'salmon' : 'white') } }}
											InputLabelProps={{
												shrink: true,
												style: { backgroundColor: 'white' }
											}}
											onChange={(event) => this.handleChange('date_notarized', event)}
										/>
									</>
								) : null }								
							</div>
						</div>

						<Typography style={{ display: 'flex', marginTop: 0 }} >
							<span style={{ fontWeight: 'bold' }}>Courtier :</span>
							<span style={{ marginLeft: 5, marginRight: 5 }} > <a style={{ textDecoration: 'underline', color: 'rgb(0, 0, 0, 0.87)' }} href={"users/edit/" + broker_id} target="_blank">{broker_name}</a></span>
							<span style={{ fontWeight: 'bold' }}>id :</span>
							<span style={{ marginLeft: 5, marginRight: 5 }}> {broker_id}</span>
							<span style={{ fontWeight: 'bold' }}>Code :</span>
							<span style={{ marginLeft: 5, marginRight: 5 }}> {broker_code}   </span>
						</Typography >

						<TextField
							fullWidth={true}
							label="Mls"
							value={this.state.mls}
							InputProps={{ style: { backgroundColor: (this.state.badMls ? 'salmon' : 'white') } }}
							onChange={(event) => this.handleChange('mls', event)}
							type="number"
							margin="normal"
						/>

						<a style={{ textDecoration: 'underline', color: 'rgb(0, 0, 0, 0.87)' }} href={"/viewdelivery/" + this.props.listing.id} target="_blank">Photos</a>

						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
							{this.state.centrisData.length > 0 &&
								<Button style={{ display: 'flex', alignSelf: 'flex-end' }} onClick={() => this.tabClick('showCentrisInfo')}>Centris Info</Button>
							}
							{this.state.centrisListing.length > 0 &&
								<Button style={{ display: 'flex', alignSelf: 'flex-end' }} onClick={() => this.tabClick('showCentrisListing')}>Centris Listing</Button>
							}
						</div>


						{this.state.showCentrisListing == true &&
							<Card style={{ display: "flex", height: 200, overflowY: 'scroll' }}>
								<CardContent style={{ flex: '1 0 auto', padding: 10 }}>
									<Paper style={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>Listing CENTRIS de ce courtier</Paper>
									<Table className="">
										<TableHead>
											<TableRow>
												<TableCell className="listing-list-cell">Mls</TableCell>
												<TableCell className="listing-list-cell">Adresse</TableCell>
												<TableCell className="listing-list-cell"></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												this.state.centrisListing.map(row => (
													<TableRow className="" key={row.id} >
														<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap' }} >{row.mls}  </TableCell>
														<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap', backgroundColor: 'rgb(249, 247, 247' }}>
															<ul className="no-bullet">
																<li>{row.civic_number_start} {row.street}</li>
																<li>{row.appartement}</li>
																<li>{row.municipality.description}</li>
																<li>{row.postalcode}</li>
															</ul>
														</TableCell>
														<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap', backgroundColor: 'rgb(249, 247, 247' }}><Button style={{ fontSize: 12 }} onClick={() => this.changeMls(row)}> Assigner</Button> </TableCell>
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						}

						{this.state.showCentrisInfo == true &&
							<Card style={{ display: "flex", height: 200, overflowY: 'scroll' }}>
								<CardContent style={{ flex: '1 0 auto', padding: 10 }}>
									<Paper style={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>  CENTRIS  </Paper>
									<Table className="">
										<TableHead>
											<TableRow>
												<TableCell className="listing-list-cell">Adresse</TableCell>
												<TableCell className="listing-list-cell">Brokers</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>

											{this.state.centrisData.map(row => (<TableRow className="" key={row.id} >
												<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap' }}>
													<ul className="no-bullet">
														<li>{row.civic_number_start} {row.street}</li>
														<li>{row.appartement}</li>
														<li>{row.municipality.description}</li>
														<li>{row.postalcode}</li>
													</ul>
												</TableCell>

												<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap', backgroundColor: 'rgb(249, 247, 247' }}>
													<ul className="no-bullet">
														{this.centrisBrokerString(row, 'main_broker')}
														{this.centrisBrokerString(row, 'second_broker')}
														{this.centrisBrokerString(row, 'third_broker')}
														{this.centrisBrokerString(row, 'fourth_broker')}
													</ul>
												</TableCell>

											</TableRow>
											))}
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						}
						<TextField
							fullWidth={true}
							label="Adresse"
							value={this.state.address}
							onChange={(event) => this.handleChange('address', event)}
							margin="normal"
						/>

						<TextField
							fullWidth={true}
							label="App #"
							value={this.state.app}
							onChange={(event) => this.handleChange('app', event)}
							margin="normal"
						/>

						<TextField
							fullWidth={true}
							label="Ville"
							value={this.state.city}
							onChange={(event) => this.handleChange('city', event)}
							margin="normal"
						/>

						<TextField
							fullWidth={true}
							label="Code postal"
							value={this.state.postalcode}
							onChange={(event) => this.handleChange('postalcode', event)}
							margin="normal"
						/>

						{this.state.shooting.length == 0 &&
							<Paper style={{ marginTop: 10, textAlign: 'center' }}>Aucun shooting </Paper>
						}
						{this.state.shooting.length > 0 &&
							<>
								<Paper style={{ marginTop: 10, textAlign: 'center' }}> Shooting(s) </Paper>
								<Table className="">
									<TableHead>
										<TableRow>
											<TableCell className="listing-list-cell">Id</TableCell>
											<TableCell className="listing-list-cell">Date</TableCell>
											<TableCell className="listing-list-cell">Info</TableCell>
											<TableCell className="listing-list-cell">Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.shooting.map(row => (<TableRow className="" key={row.id} >
											<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap' }}>{row.id}</TableCell>
											<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap', backgroundColor: 'rgb(249, 247, 247' }}>{moment(row.scheduleddate).format('YYYY-MM-DD')}</TableCell>
											<TableCell className="listing-list-cell" component="th" scope="row">
												<ul className="no-bullet">
													<li><b>Completé :</b> {this.shootingCompleted(row.state) == true ? 'oui' : 'non'}</li>
													<li><b>Statut :</b>{this.mapShootingState(row.state)}</li>
													<li><b>Tech :</b>{this.techName('tech', row)}</li>
													<li><b>PP tech :</b>{this.techName('pp_tech', row)}</li>
													{row.cancellation_reason != null &&
														<li><b>Raison annullation :</b>{row.cancellation_reason}</li>
													}
													{row.reshoot_note != null &&
														<li><b> Re shoot note:</b>{row.reshoot_note}</li>
													}
													<li><b>Note :</b>{row.note}</li>
													{row.tech_note != null &&
														<li><b>Tech note :</b>{row.tech_note}</li>
													}
												</ul>
											</TableCell>
											<TableCell className="listing-list-cell" component="th" scope="row">
												<>
													{
														row.invoice && row.invoice.invoice_id > 0 ? (
															<Button onClick={()=>this.GoToInvoice(row)} color="primary" >
																Access invoice 
															</Button>
														) : (
															<Button onClick={()=>this.GenerateInvoice(row.id)} color="primary" >
																Generate invoice
															</Button>
															
														)
														
													}
													{this.numberEqualsAny(row.state, [1,2,6,8,12]) &&
														<Button onClick={()=>this.CancelShooting(row.id)} color="primary" >
															Cancel
														</Button>
													}
												</>
											</TableCell>
										</TableRow>
										))}
									</TableBody>
								</Table>
							</>
						}

				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose('ok', this.props.data)} color="primary" autoFocus>
						Ok
            		</Button>
					<Button onClick={this.handleClose('cancel', this.props.data)} color="primary" >
						Cancel
              		</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ListingEdit;
