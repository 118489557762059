import React from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import {ArrowBackIos, ArrowForwardIos} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import './admin.css';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListingEdit from './ListingEdit';
import ZoneButton from './ZoneButton';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const invalideColor ='#F5C3C2';
const valideColor ='#98FB98';
const lockedTechColor = '#CCC';
const normalTechColor = 'transparent';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        fontSize:23,
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
        cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    wideTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400
    },
    recalculateDistancesButton:{
        marginTop: '5px',
        float: 'right',
        marginRight: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'green',
        color:'white'
    },
    forceAssignContainer:{
        float: 'right',
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'white',
        border:'2px solid red',
        borderRadius:'5px',
        color:'black',
        marginTop: '5px'
    },
    zoneDetailsExpandSwitch:{
        float: 'right',
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'white',
        border:'2px solid orange',
        borderRadius:'5px',
        color:'black',
        marginTop: '5px'
    },
    shootingExpandSwitch:{
        float: 'right',
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'white',
        border:'2px solid blue',
        borderRadius:'5px',
        color:'black',
        marginTop: '5px'
    },
    forceAssignLabel:{
        color:'black',
        fontWeight: 800
    },
    expandZoneLabel:{
        color:'black',
        fontWeight: 800
    },
    expandShootingsLabel:{
        color:'black',
        fontWeight: 800
    },
    title:{
        fontSize:30,
        marginBottom:20,
        textAlign:'center'
    },
    card:{
        marginBottom:20
    },
    itemCard : {
        marginBottom:10,
        margin: 5,
        backgroundColor:'rgba(62,80,178,0.1)'
    },
    cardScroll :{
        maxHeight:560,
        overflowY:'scroll'
    },
    noShooting:{
        margin:40,
        padding:10,
        textAlign:'center'
    },
    mainCol:{
        height:'100%',
        unavailabilityTitle:{
            fontWeight: 800
        },
        //overflowY:'scroll'
    },
    unassignedShoots:{
        minHeight:'500px'
    },
    dispatchCheckbox:{
        paddingTop:0,
        paddingBottom:0
    },
    selfAssign:{
        backgroundColor:'white'
    },
    techLock:{
        float: 'right'
    },
    zoneSelector:{
        marginLeft: '56px',
        marginTop: '20px',
        marginBottom: '20px',
        minWidth: '200px'
    },
    unassignButton:{
        backgroundColor:'red',
        color:'white',
        margin:'0px 0px 20px 20px',
        display:'block',
        float:'right'
    }
});


class ShootingPlanner extends React.Component {
    constructor(props) {
        super(props);

        var dispatch={};

        Object.defineProperties(dispatch, {
            needTo: {
                enumerable:false,
                get: function () {
                    for(let props in this){
                            if(this[props]===true){
                                return true;
                            }
                    }
                    return false;
                } 
            },reset: {
                enumerable:false,
                value:function(){
                    for(let props in this){
                        if(this[props]===true){
                            this[props]=false;
                        }
                    }
                }
            }
        });
 
        this.state = {
            techs:[],
            unAssignedShootings:[],
            selectedDate : moment().format('YYYY-MM-DD'),
            allTech:[],
            dropZoneColor:normalTechColor,
            dlgShow: false,
            dlgConfirm:false,
            dlgDelegate:null,
            dispatch,
            forceAssignEnabled: false,
            shootingsExpanded: false,
            zoneDetailsExpanded: false,
            showPartTime: false,
            loaded:false,
            loadingData: false,
            listEditShow:false,
            listingToEdit:null,
            zones: [],
            selected_zone_ids: [-1],
            locks:[],
            loadingLocks:false,
            notifyClicked: false
        };
    }

    componentWillMount() {
        if(this.props.location.state) {
            this.setState({selectedDate: moment(this.props.location.state).format('YYYY-MM-DD')})
        }
    }

    componentDidMount() {
        this.getShooting()
            .then(()=>{
                this.getZones();
            })
    }

    fetchTechLocksForSelectedDay = () =>
    {
        this.setState({loadingLocks:true}, () => {
            window.Hauslife.getAllTechLocksForTheDay(this.state.selectedDate)
            .then((res)=>{
                let locks = res.data.locks;
                this.setState({
                    locks:locks,
                    loadingLocks: false
                },()=>{
                    this.calculateColorsForTechs();     
                    this.sortTechs();              
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
                this.setState({loadingLocks: false});
            });
        });

    }

    sortTechs = () => {
        //Unlocked on top and lowest shoots on top
        this.state.techs.sort((firstTech, secondTech)=>{
            if(firstTech.shootings.length > secondTech.shootings.length){
                return -1
            }
            else if(firstTech.shootings.length < secondTech.shootings.length){
                return 1
            }
            else if(!this.isTechDayLocked(firstTech.techId) && this.isTechDayLocked(secondTech.techId)){
                return -1;
            }
            else if(this.isTechDayLocked(firstTech.techId) && !this.isTechDayLocked(secondTech.techId)){
                return 1;
            }
            else if(this.isTechDayLocked(firstTech.techId) && this.isTechDayLocked(secondTech.techId)){
                if(firstTech.shootings.length == secondTech.shootings.length){
                    return 0;
                }
                if(firstTech.shootings.length > secondTech.shootings.length){
                    return 1
                }
                else{
                    return -1;
                }
            }
            else{
                if(firstTech.shootings.length == secondTech.shootings.length){
                    return 0;
                }
                if(firstTech.shootings.length > secondTech.shootings.length){
                    return 1
                }
                else{
                    return -1;
                }
            }
        })

        console.log(this.state.techs)
    }

    calculateColorsForTechs = () =>
    {
        let dropZoneColor = [];

        for(let i=0; i<this.state.techs.length; i++){
            //techs[i].part_time == false ? dropZoneColor[techs[i].techId] = '#73C2FB' : dropZoneColor[techs[i].techId] = 'transparent';
            dropZoneColor[this.state.techs[i].techId] = this.isTechDayLocked(this.state.techs[i].techId) ? lockedTechColor : normalTechColor;
        }

        this.setState({dropZoneColor:dropZoneColor});
    }

    shootingActivityResult (res) {
        var data = res.data.data;
        var techs =[];
        var unAssignedOut = [];
        var keys = Object.keys(data);
      
        if(data.scheduledTechs==undefined){
            data.scheduledTechs=[ 
                { 
                    firstname:window.Hauslife.userObject.firstname,
                    lastname:window.Hauslife.userObject.lastname,
                    id: window.Hauslife.userObject.id
                }
            ];
        }

        let scheduledTechs = data.scheduledTechs;

        data.scheduledTechs = data.scheduledTechs.filter( (element)=>{
            if( keys.indexOf(element.user.id.toString())!= -1){
                return false;
            }
            return true;
        });

        for(var prop in data){
            var obj = {
                techName : 'Non assignée',
                techId:-1,
                key:-1,
                distanceTotal:0,
                shootings:[],
                unavailabilities: [],
                zone_ids: []
            };

            if(prop=='scheduledTechs'){
                data[prop].forEach( (_data)=>{
                    let tech = _data.user;

                    let unavailabilities = tech.unavailabilities;
                    unavailabilities.sort((a, b)=>{
                        return moment.utc(a.start).diff(moment.utc(b.start))    
                    })

                    unavailabilities.forEach((unavailability)=>{
                        unavailability.unavailability = true;
                        unavailability.scheduleddate = unavailability.start
                        unavailability.schedule =  moment(unavailability.start).format('HH:mm') + ' - ' + moment(unavailability.end).format('HH:mm');
                    })

                    obj = {
                        techName :tech.firstname + ' ' + tech.lastname,
                        key : tech.id,
                        techId : tech.id,
                        shootings:[],
                        distanceTotal:0,
                        part_time: tech.part_time,
                        unavailabilities: unavailabilities,
                        depot: tech.depot,
                        zone: "",
                        backupZone: "",
                        zone_ids: [],
                        skills: "",
                        techDailyActivity: []
                    };

                    if(tech.userSkills){
                        let userHasTwoDSkill = false;
                        let userHasDroneSkill = false;
                        for(let j=0; j<tech.userSkills.length; j++){
                            if(tech.userSkills[j].skill.name == "2d"){
                                userHasTwoDSkill = true;
                            }
                            if(tech.userSkills[j].skill.name == "drone"){
                                userHasDroneSkill = true;
                            }

                        }
                        if(userHasDroneSkill == true){
                            obj.skills = "(2D + 3D + drone)"
                        }
                        else if(userHasTwoDSkill == true){
                            obj.skills = "(2D + 3D)"
                        }
                        else{
                            obj.skills = "(3D)"
                        }
                    } 
                    

                    if(tech.userZones){
                        let zone = "";
                        let backupZone = "";
                        let multipleBackupZones = false;

                        for(let j=0; j<tech.userZones.length; j++){
                            obj.zone_ids.push(tech.userZones[j].zone.id);
                            if(tech.userZones[j].backup == true){
                                if(backupZone == ""){
                                    backupZone = tech.userZones[j].zone.zone_name + "(" + tech.userZones[j].zone.id + ")";
                                }
                                else{
                                    backupZone += ", " + tech.userZones[j].zone.zone_name + "(" + tech.userZones[j].zone.id + ")";
                                    multipleBackupZones = true;
                                }
                            }
                            else{
                                if(zone == ""){
                                    zone = tech.userZones[j].zone.zone_name + "(" + tech.userZones[j].zone.id + ")";
                                }
                                else{
                                    zone += ", " + tech.userZones[j].zone.zone_name + "(" + tech.userZones[j].zone.id + ")";
                                } 
                            }                           
                        }
                        obj.zone = zone
                        obj.backupZone = backupZone
                        obj.multipleBackupZones = multipleBackupZones;
                    }

                    techs.push(obj);
                });
                continue;
            }
            
            var shootings = data[prop];

            if(shootings.length==0) continue;

            if(prop != 'null'){
                let currentTech = shootings[0].tech;
                obj.techName = currentTech.firstname + ' ' + currentTech.lastname;

                for(let i=0; i<scheduledTechs.length; i++){
                    if(scheduledTechs[i].user.id == currentTech.id && scheduledTechs[i].user.userSkills){
                        if(scheduledTechs[i].user.userSkills.length > 2){
                            obj.skills = "(2D + 3D + Drone)"
                        }
                        else if(scheduledTechs[i].user.userSkills.length > 1){
                            obj.skills = "(2D + 3D)"
                        }
                        else{
                            obj.skills = "(3D)"
                        }

                        let unavailabilities = scheduledTechs[i].user.unavailabilities;

                        unavailabilities.sort((a, b)=>{
                            return moment.utc(a.start).diff(moment.utc(b.start))    
                        })

                        unavailabilities.forEach((unavailability)=>{
                            unavailability.unavailability = true;
                            unavailability.scheduleddate = unavailability.start
                            unavailability.schedule =  moment(unavailability.start).format('HH:mm') + ' - ' + moment(unavailability.end).format('HH:mm');
                        })                        

                        obj.unavailabilities = unavailabilities;
                        break;
                    } 
                }

                if(currentTech.userZones){
                    let zone = "";
                    let backupZone = "";
                    let multipleBackupZones = false;

                    for(let j=0; j<currentTech.userZones.length; j++){
                        obj.zone_ids.push(currentTech.userZones[j].zone.id);
                        if(currentTech.userZones[j].backup == true){
                            if(backupZone == ""){
                                backupZone = currentTech.userZones[j].zone.zone_name + "(" + currentTech.userZones[j].zone.id + ")";
                            }
                            else{
                                backupZone += ", " + currentTech.userZones[j].zone.zone_name + "(" + currentTech.userZones[j].zone.id + ")";
                                multipleBackupZones = true;
                            }
                        }
                        else{
                            if(zone == ""){
                                zone = currentTech.userZones[j].zone.zone_name + "(" + currentTech.userZones[j].zone.id + ")";
                            }
                            else{
                                zone += ", " + currentTech.userZones[j].zone.zone_name + "(" + currentTech.userZones[j].zone.id + ")";
                            }
                        }                           
                    }
                    obj.zone = zone
                    obj.backupZone = backupZone
                    obj.multipleBackupZones = multipleBackupZones;
                }
                
                obj.techId = shootings[0].tech_id;
                obj.key = shootings[0].tech_id;
                obj.depot = currentTech.depot;                
                obj.part_time = currentTech.part_time;
            }

            shootings.forEach((shooting)=>{
                let shootingAddress = "";
                
                if(shooting.listing.app !== null && shooting.listing.app !== ""){
                    shootingAddress = shooting.listing.address + ', #' + shooting.listing.app  + ', ' + shooting.listing.city + ' ' + shooting.listing.postalcode
                }
                else{
                    shootingAddress = shooting.listing.address + ', ' + shooting.listing.city + ', ' + shooting.listing.postalcode
                }

                var s = {
                    shootingId:shooting.id,
                    listingId: shooting.listing.id,
                    address: shootingAddress,
                    schedulerOverride: shooting.ignore_scheduler,
                    force_assigned: shooting.force_assigned,
                    scheduleddate : new Date(shooting.scheduleddate),
                    rooms : shooting.listing.room_count,
                    broker : shooting.listing.broker.firstname + ' '+shooting.listing.broker.lastname,
                    brokerId : shooting.listing.broker.id,
                    distance : shooting.distance,
                    transit_time: shooting.transit_time,
                    note: shooting.note,
                    only360 : shooting.only_360,
                    billable:shooting.billable,
                    rooms:shooting.room_count,
                    banner: (shooting.listing.broker.franchise_id!=null?shooting.listing.broker.franchise.name:null),
                    reshoot:false,
                    listing:shooting.listing,
                    drone: shooting.drone,
                    seasonal: shooting.seasonal,
                    blue_hour: shooting.blue_hour,
                    zone: shooting.zone,
                    use_backup: shooting.use_backup
                };

                shooting.listing.shootings =  shooting.listing.shootings.filter( function(value){if(value.state!=10 && value.state!=12 )return true});

                if(shooting.listing.shootings.length>1){
                    s.reshootIndex=0;
                    shooting.listing.shootings.forEach((value,idx)=>{
                        if(value.id==shooting.id){
                            s.reshootIndex=idx+1;
                        }
                    });
                    s.reshoot=true;
                }

                if(shooting.dispatch_time!=null && this.props.mode!='dashBoard'){
                    s.dispatch_time=moment(shooting.dispatch_time).format('YYYY-MM-DD HH:mm');
                    s.backgroundColor='#73C2FB';
                }
                
                if(  shooting.distance !=null ){
                    obj.distanceTotal+=shooting.distance;
                }  else {
                    s.distance='';
                }

                if(this.props.mode=='dashBoard'){
                    s.schedule  =  moment(s.scheduleddate).format('YYYY-MM-DD HH:mm');  
                } else {
                    s.schedule  =  moment(s.scheduleddate).format('HH:mm');  
                }

                if(shooting.estimated_duration!=null){
                    s.endTime = new Date(s.scheduleddate.getTime());
                    s.endTime.setSeconds(shooting.estimated_duration);
                    s.schedule+= ' - ' + moment(s.endTime).format('HH:mm');
                }
                obj.shootings.push(s);
            });

            obj.shootings = obj.shootings.sort((a,b)=>{
                return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X') 
            })

            obj.techDailyActivity = obj.shootings.concat(obj.unavailabilities);

            obj.techDailyActivity = obj.techDailyActivity.sort((a,b)=>{
                return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X') 
            })

            if(prop!='null'){
                techs.push(obj);
            } else {
                unAssignedOut.push(obj);
            }
        }

        techs.forEach ( (tech)=>{
            tech.days = {};
            tech.shootings.forEach ( (item)=> {
                var key =  moment( item.scheduleddate).format('YYYY-MM-DD');  ;
                if(tech.days[key]==undefined){
                    tech.days[key]=[];
                }
                tech.days[key].push(item);
            });
        }); 
       
        if(unAssignedOut.length==0){
            unAssignedOut.push({key:-1,techId:-1,techName:'Non assignée',shootings:[]});
        }

        let dropZoneColor = [];

        for(let i=0; i<techs.length; i++){
            dropZoneColor[techs[i].techId] = this.isTechDayLocked(techs[i].techId) ? lockedTechColor : normalTechColor;
        }

        this.setState({
            dropZoneColor:dropZoneColor,
            techs:techs,
            unAssignedShootings:unAssignedOut,
            loaded:true,
            loadingData: false
        });        
    }

    getShooting (value){
        return new Promise((resolve, reject)=>{  
            this.setState({loaded:false});
            if(value==undefined){
                // Should have used the state value
                // value = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                value = moment(this.state.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                //value = moment('2020-07-06').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            }
            var service = 'allDailyShootings';

            switch(this.props.mode){
                case 'dashBoard':                
                    window.Hauslife.shootingFromTech(this.props.userId)
                        .then((res)=> {
                            this.setState({loaded:true});
                            this.shootingResult(res);
                            this.fetchTechLocksForSelectedDay();
                            this.resortZones();
                            resolve();
                        }).catch((e) => {
                            console.log('Some error : ',e);
                            reject(e)
                        });
                    break;
                default:
                    window.Hauslife.dailyShootingActivity(moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
                        .then((res)=> {
                            this.setState({loaded:true});
                            this.shootingActivityResult(res);
                            this.fetchTechLocksForSelectedDay();
                            this.resortZones();
                            resolve();
                        }).catch((e) => {
                            console.log('Some error : ',e);
                            reject(e)
                        });
            }
        })
    }

    getZones = () => {
        window.Hauslife.getZones()
            .then((res)=>{
                let zones = res;

                zones.sort((a,b)=> {
                    return a.zone_name.localeCompare(b.zone_name)
                });

                zones.sort((a,b)=> {
                    return this.shootCount(b.id) - this.shootCount(a.id)
                });

                zones.unshift({zone_name:"Toutes les zones", id:-1})
                this.setState({
                    zones:zones
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });
    }

    resortZones = () => {
        let zones = this.state.zones;

        zones.sort((a,b)=> {
            return a.zone_name.localeCompare(b.zone_name)
        });

        zones.sort((a,b)=> {
            return this.shootCount(b.id) - this.shootCount(a.id)
        });

        let firstZoneName = "Toutes les zones";

        zones.sort((a,b)=>{return a.zone_name == firstZoneName ? -1 : b.zone_name == firstZoneName ? 1 : 0})

        this.setState({
            zones:zones
        });
    }

    alertClose = (button,data) => {
        this.setState({dlgShow: false, dlgData:null });
        
        if(button=='cancel') return;

        switch(data){
            case 'dispatch':
                let sId=[];
                for(var p in this.state.dispatch){
                    if(this.state.dispatch[p]===true){
                        sId.push(p)
                    }
                }

                window.Hauslife.dispatchShooting(sId)
                    .then((res) => {
                        if(res.data.userDispatched==0){
                            this.state.dispatch.reset();

                            this.setState({ 
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: "Aucun technicien n'est disponible pour la répartition.",
                                dispatch:this.state.dispatch
                            });

                        } else {
                            this.getShooting(this.state.selectedDate);
                            
                            var totalShootiong =Object.keys( this.state.dispatch).reduce((acc,value)=>{ this.state.dispatch[value]&&acc++;return acc  },0);
                            var dispatchedShooting = res.data.shootingDispatched.length;

                            this.state.dispatch.reset();
                            this.setState({ 
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: `${dispatchedShooting} / ${totalShootiong} des captures on étés réparties.`,
                                dispatch:this.state.dispatch
                            });
                        }
                    })
                    .catch((e) => {
                        console.log('Some error : ',e);
                    });
                break;
            case 'unAssign':
                    window.Hauslife.assignProdShooting(false, this.state.forceAssignEnabled, this.state.unassign.shootingId,-1)
                        .then((res) => {                
                            this.getShooting(this.state.selectedDate);
                
                            this.setState({ 
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: "La déassignation a été effectuée avec succès."
                            });                        
                        })
                        .catch((err)=>{
                            console.log(err);
                            this.setState({ 
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: "La déassignation n'a pas pu être effectuée."
                            });  
                        })
                    break;
            default:
        }
    }

    handleChange = name => event => {
        var value=event.target.value;    
        this.setState({loadingData:true });    

        clearTimeout(this.dateTimer);
        this.dateTimer=setTimeout(()=>{    
            this.setState({loaded:false });        
            this.getShooting(value);
        },800);

        this.setState({ selectedDate: event.target.value });
    }

    changeCalendayDay = (forward = false) => { 
        let targetDay;

        if(forward){
            targetDay = moment(this.state.selectedDate).add(1,'day').format('YYYY-MM-DD');
        }
        else{
            targetDay = moment(this.state.selectedDate).subtract(1,'day').format('YYYY-MM-DD');
        }

        this.setState({loadingData:true, selectedDate: targetDay});  

        clearTimeout(this.dateTimer);
        this.dateTimer=setTimeout(()=>{    
            this.setState({loaded:false });        
            this.getShooting(targetDay);
        },800);
    }

    dragStart = (shooting,sourceTech) => event => {
        if(this.props.mode=='dashBoard'){
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        var currentStartTime = shooting.scheduleddate.getTime();
        var currentEndTime = currentStartTime + 3600000;
        if(shooting.endTime!=undefined){
            currentEndTime=shooting.endTime.getTime();
        }
        
        var colors = this.state.techs.map( (item)=>{
            let currentTech = item;
            let color= item.shootings.reduce((acc,currentValue)=>{
                if(acc==invalideColor) {
                    return acc;
                }

                var startTime = currentValue.scheduleddate.getTime();
                var endTime = startTime + 3600000;
                if(currentValue.endTime!=undefined){
                    endTime = currentValue.endTime.getTime();
                }

                if(startTime >= currentStartTime && endTime <= currentEndTime){
                    return invalideColor;                    
                }
                 
                return valideColor;
            },valideColor );

            if(this.isTechDayLocked(currentTech.techId) == true)
            {
                color = lockedTechColor;
            }

            if(this.state.forceAssignEnabled){
                color = valideColor;
            }

            return {id:item.techId,color};
        });

        colors = (
            function(arr){
                return arr.reduce((obj, item,index) => {
                    obj[item.id]=item.color;
                    return obj
                }, {});
            })(colors);

        this.setState({ 
            dropZoneColor:colors,
            shootingInDrag:shooting,
            sourceTech
        });
    } 

    dragEnter = tech  => event =>{
        event.preventDefault();
        event.stopPropagation();
    }
    dragLeave= tech  => event =>{
        event.preventDefault();
    }
    dragOver = shooting  => event =>{
        event.preventDefault();
    } 
    dragDrop = tech  => event =>{
        event.preventDefault();
        // Drop on same tech (drop on origin)
        if(tech.techId==this.state.sourceTech.techId){
            return;
        }
        // The tech is red or locked, so no drop allowed
        if(this.state.dropZoneColor[tech.techId]==invalideColor){//  || this.state.dropZoneColor[tech.techId]==lockedTechColor){
            if(this.state.dropZoneColor[tech.techId]==lockedTechColor)
            {
                window.alert("Tech is currently locked for the day!");
            }
            return;
        }
        
        var shootings =  this.state.sourceTech.shootings;
        var shootingInDrag = this.state.shootingInDrag;


        
        window.Hauslife.assignProdShooting(false, this.state.forceAssignEnabled, this.state.shootingInDrag.shootingId,tech.techId)
            .then((res) => {
                // remove shooting from source tech
                if(res.data.success===false){
                    this.setState({ 
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "Conflit d'horaire, l'assignation n'a pas été effectuée."
                    });
                    return;
                }
                this.state.sourceTech.shootings =  shootings.filter( (item)=>{
                    if(item.shootingId==shootingInDrag.shootingId){
                        return false;
                    }            
                    return true;
                });

                if(tech.techId > 0){
                    shootingInDrag.force_assigned = this.state.forceAssignEnabled;
                }

                tech.shootings.push(shootingInDrag);

                if(res.data.shootings){
                    tech.shootings.forEach((shooting)=>{
                        res.data.shootings.forEach((updatedShoot)=>{
                            if(shooting.shootingId == updatedShoot.id){
                                shooting.distance = updatedShoot.distance;
                                shooting.transit_time = updatedShoot.transit_time;
                            }
                        })
                    })

                    let distanceTotal = 0;

                    tech.shootings.forEach((shooting)=>{
                        distanceTotal += shooting.distance;
                    })

                    tech.distanceTotal = distanceTotal;
                }

                if(this.state.sourceTech.techDailyActivity){
                    this.state.sourceTech.techDailyActivity =  this.state.sourceTech.techDailyActivity.filter( (item)=>{
                        if(item.shootingId==shootingInDrag.shootingId){
                            return false;
                        }            
                        return true;
                    });
                }                

                if(tech.techDailyActivity){
                    tech.techDailyActivity.push(shootingInDrag);
                    tech.techDailyActivity = tech.techDailyActivity.sort((a,b)=>{
                        return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X') 
                    })
                }

                this.state.dispatch[shootingInDrag.shootingId]=false;
                this.setState({techs:this.state.techs,dispatch:this.state.dispatch}, ()=>{
                    this.sortTechs();
                });
                this.resortZones();

                if(this.state.forceAssignEnabled || shootingInDrag.schedulerOverride){
                    this.fetchTechLocksForSelectedDay();
                }                
            }).catch((e) => {
                console.log('Some error : ',e);
                this.setState({ 
                    dlgShow: true,
                    dlgConfirm:false,
                    dlgTitle: 'HausValet',
                    dlgText: "La déassignation n'a pas pu être effectuée."
                }); 
            });

        this.dragCounter=0;
    } 

    dragEnd(){ 
        return event =>{
            event.preventDefault();

            let dropZoneColor = [];

            for(let i=0; i<this.state.techs.length; i++){
                dropZoneColor[this.state.techs[i].techId] = this.isTechDayLocked(this.state.techs[i].techId) ? lockedTechColor : normalTechColor;
            }

            this.setState({
                dropZoneColor:dropZoneColor,
                shootingInDrag:null
            });
        }
    };

    dateToText (value){
        return moment(value).format('YYYY-MM-DD');
    }

    handleBillableCheckboxChange = (shooting,source,techId) => {
        return (event) => {            
            var sho =  this.state.unAssignedShootings;
            if(sho.length>0){
                sho = sho[0].shootings;
            }

            if(source!='unassigned'){
                var tech = this.state.techs.find( (tech)=>tech.techId==techId);
                sho = tech.shootings;
            }            

            var conf = window.confirm('Voulez vous vraiment changer le statut de facturation ?');

            if(conf == false) return;
 
            var target = sho.find( (i)=>i.shootingId==shooting.shootingId);

            if(target.billable==false){
                target.billable=true;
            }  else {
                target.billable=false;
            }
            
            window.Hauslife.updateShootingBillable(target )
                .then((res)=> {

                }).catch((e) => {
                    console.log('Some error : ',e);
                }); 

            this.setState({void:1});
        }
    }

    handleDispatchCheckboxChange = (shootingId ) =>{
        return (event) => {
            if(this.state.dispatch[shootingId]==undefined){
                this.state.dispatch[shootingId]=false;
            }
            if(this.state.dispatch[shootingId]==true){
                this.state.dispatch[shootingId]=false;
            } else {
                this.state.dispatch[shootingId]=true;
            }
            this.setState({dispatch:this.state.dispatch})
        }
    }
     
    handleDispatch ( ){
        this.setState({ 
            dlgShow: true,
            dlgTitle: 'HausValet',
            dlgText: "Voulez-vous repartir les captures selectionnées ?",
            dlgConfirm:true,
            dlgData:'dispatch'
        })
    }

    recalculateShootingDistances(){
        window.Hauslife.recalculateShootingDistances(moment(this.state.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
            .then((res)=> {
                this.getShooting(moment(this.state.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
            }).catch((e) => {
                console.log('Some error : ',e);
            });
    }

    toggleForceAssign(){
        this.setState({forceAssignEnabled:!this.state.forceAssignEnabled});
    }

    toggleShootingsExpanded(){        
        this.setState({shootingsExpanded:!this.state.shootingsExpanded, loaded: false}, ()=>{
            this.setState({loaded:true});
        });
    }

    toggleZoneDetailsExpanded(){
        this.setState({zoneDetailsExpanded:!this.state.zoneDetailsExpanded, loaded: false}, ()=>{
            this.setState({loaded:true});
        });
    }
    
    getDispatchCheckBoxValue (shootingId){
        var disp = this.state.dispatch[shootingId];

        if(this.state.dispatch[shootingId]==undefined) return false;
        return this.state.dispatch[shootingId]
    }

    countTotalShootings = () =>
    {
        let total = 0;
        this.state.techs.forEach((tech, i)=>{
            total += tech.shootings.length;
        });

        this.state.unAssignedShootings.forEach((tech, i)=>{
            total += tech.shootings.length;
        });

        return total;
    }

    deAssignTech = (row)=>()=>{
        this.setState({ 
            dlgShow: true,
            dlgTitle: 'HausValet',
            dlgText:'Voulez-vous déassigner ce shoot?',
            dlgConfirm:true,
            dlgData:'unAssign',
            unassign:row
        });
    }

    assignTech = (row) => (e) => {
        window.Hauslife.assignProdShooting(false, this.state.forceAssignEnabled, row.shootingId,e.target.value)
            .then((res) => {
                this.getShooting(this.state.selectedDate);

                // remove shooting from source tech
                if(res.data.success===false){
                    this.setState({ 
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "Conflit d'horaire, l'assignation n'a pas été effectuée."
                    });
                    return;
                } else {

                    this.setState({ 
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "L'Assignation a été effectuée avec succès."
                    });

                    if(this.state.forceAssignEnabled){
                        this.fetchTechLocksForSelectedDay();
                    }
                }            
            });
    }

    showListing(item){
        this.setState({listEditShow:true,listingToEdit:item.listing});
    }

    handleZoneChange = name => event => {
        this.setState({selected_zone_ids: event.target.value})
    }

    toggleLockForTech = (tech_id) =>
    {
        if(this.isTechDayLocked(tech_id)==true)
        {
            this.unlockDayOfTech(tech_id);
        }
        else
        {
            this.lockDayOfTech(tech_id);
        }
    }

    lockDayOfTech = (tech_id) =>
    {
        window.Hauslife.updateLockStatus(tech_id, true, this.state.selectedDate)
        .then((res)=>{
            this.fetchTechLocksForSelectedDay();
        }).catch( (e)=>{            
            console.log('Error   : ',e);
        });
    }

    unlockDayOfTech = (tech_id) =>
    {
        window.Hauslife.updateLockStatus(tech_id, false, this.state.selectedDate)
        .then((res)=>{
            this.fetchTechLocksForSelectedDay();
        }).catch( (e)=>{
            this.setState({ 
                dlgShow: true,
                dlgConfirm:false,
                dlgTitle: 'HausValet',
                dlgText: `Impossible de débarrer la journée du photographe, il se peut qu\'il aille des captures avec un conflit.`
            });
            console.log('Error   : ',e);
        });
    }

    isTechDayLocked = (tech_id) =>
    {
        for(var i = 0; i < this.state.locks.length; i++)
        {
            if(this.state.locks[i].tech_id == tech_id)
            {
                return true;
            }
        }
    }

    filterTechs = (tech) => {
        let validTech = false;

        tech.zone_ids.forEach((zone_id)=>{
            for(let i=0; i<this.state.selected_zone_ids.length; i++){
                if(this.state.selected_zone_ids[i] == -1 || this.state.selected_zone_ids[i] == zone_id){
                    validTech = true;
                    break;
                }
            }
        })

        return validTech;
    }

    filterUnassignedShootings = (shooting) => {
        let validShooting =  false;

        //Return all shootings with no zones
        if(!shooting.zone){
            return true;
        }

        for(let i=0; i<this.state.selected_zone_ids.length; i++){
            if(this.state.selected_zone_ids[i] == -1){
                validShooting = true;
                break;
            }
            if(shooting.zone && shooting.zone.id == this.state.selected_zone_ids[i]){
                validShooting = true;
            }
        }

        return validShooting;
    }

    activeZone = (zone_id) => {
        return this.state.selected_zone_ids.indexOf(zone_id) >= 0;
    }

    toggleZone = (zone_id) => {
        let zoneIds = this.state.selected_zone_ids;
        if(this.activeZone(zone_id)){
            zoneIds.splice(zoneIds.indexOf(zone_id), 1);
        }
        else{
            zoneIds.push(zone_id);
        }
        this.setState({selected_zone_ids: zoneIds})
    }

    shootCount = (zone_id) =>{
        let shootCount = 0;        

        if(this.state.unAssignedShootings && this.state.unAssignedShootings.length == 1 && this.state.unAssignedShootings[0].shootings && this.state.unAssignedShootings[0].shootings.length > 0){
            this.state.unAssignedShootings[0].shootings.forEach((shooting)=>{
                if(shooting.zone && shooting.zone.id == zone_id){
                    shootCount++;
                }
            })
        }
        return shootCount;
    }

    readableTransitTime = (seconds) => {
        return moment.utc(seconds*1000).format('HH:mm:ss');
    }

    firstShoot = (shootings, shoot) => {
        for(let i=0; i<shootings.length; i++){            
            if(shootings[i].shootingId == shoot.shootingId){
                return true;
            }
            if(shootings[i].unavailability != true){
                return false;
            }
        }
    }

    lastShoot = (shootings, shoot) => {
        for(let i=shootings.length-1; i>=0; i--){            
            if(shootings[i].shootingId == shoot.shootingId){
                return true;
            }
            if(shootings[i].unavailability != true){
                return false;
            }
        }
    }

    onlyShoot = (shootings, shoot) => {
        let shootCount = 0;

        for(let i=0; i<shootings.length; i++){            
            if(shootings[i].unavailability != true){
                shootCount++;
            }
        }

        return shootCount == 1;
    }


    getDepartureTime = (shoot) => {
        return moment(shoot.scheduleddate).subtract(shoot.transit_time, 'seconds').format('HH:mm:ss');
    }

    getArrivalTime = (shootings, shoot) => {
        let firstShootTransit = 0;

        for(let i=0; i<shootings.length; i++){
            if(shootings[i].unavailability != true){
                firstShootTransit = shootings[i].transit_time;
                break;
            }
        }
        return moment(shoot.endTime).add(firstShootTransit, 'seconds').format('HH:mm:ss');
    }

    onNotifyClick = () => {
        this.setState({notifyClicked: true})
        window.Hauslife.sendShootingReminderToPhotographers()
            .then(() => {
                this.setState({notifyClicked: false})
                alert("Photographers have been notified!")
            })
    }

    render() {
        const { classes } = this.props;        
       
        return (
            <Paper className={classes.root}>
                { this.state.listEditShow==true &&
                    <ListingEdit 
                        style={{width:'100%'}}
                        show={this.state.listEditShow}
                        didClose={()=>this.setState({listEditShow:false})}
                        listing ={this.state.listingToEdit}
                    />
                }

                <AlertDialog 
                    show={this.state.dlgShow} 
                    didClose={this.alertClose} 
                    title={this.state.dlgTitle} 
                    text={this.state.dlgText} 
                    confirm={this.state.dlgConfirm} 
                    data={this.state.dlgData}
                />             
                  
                <Grid container className="shooting-planner">
                    { this.props.mode!='dashBoard' &&
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant="h3" gutterBottom>Planification des captures</Typography>
                        </Grid>
                    }

                    { this.props.mode=='dashBoard' &&
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant="h3" gutterBottom>Vos captures</Typography>
                        </Grid>
                    }
                    
                    <Grid item xs={12}  className="button-operations">
                        { this.props.mode!='dashBoard' &&
                            <>
                                <Button  
                                    variant="outlined"
                                    color="inherit"
                                    className={classes.recalculateDistancesButton}
                                    onClick={this.onNotifyClick}
                                    disabled={this.state.notifyClicked}
                                >
                                    <p>Notify Photographers</p>
                                </Button>
                                <Button  
                                    variant="outlined"
                                    color="inherit"
                                    className={classes.recalculateDistancesButton}
                                    onClick={this.recalculateShootingDistances.bind(this)}>
                                    <p>Actualiser</p>
                                </Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.forceAssignEnabled}
                                            onChange={this.toggleForceAssign.bind(this)}
                                            value="Assignation forcée"
                                        />
                                    }
                                    label={<Typography className={classes.forceAssignLabel}>Assignation forcée</Typography>}
                                    className={classes.forceAssignContainer}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.zoneDetailsExpanded}
                                            onChange={this.toggleZoneDetailsExpanded.bind(this)}
                                            value="Détails des zones utilisateur"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography className={classes.expandZoneLabel}>Détails des utilisateurs</Typography>}
                                    className={classes.shootingExpandSwitch}
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.shootingsExpanded}
                                            onChange={this.toggleShootingsExpanded.bind(this)}
                                            value="Détails des captures"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography className={classes.expandShootingsLabel}>Détails des captures</Typography>}
                                    className={classes.shootingExpandSwitch}
                                />
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        { this.props.mode!='dashBoard' &&
                            <Typography variant="h5" color="textPrimary" style={{display:"inline-flex", fontWeight:"bold", margin: 10}}>
                                {this.countTotalShootings() + " capture"}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        { this.props.mode!='dashBoard' &&
                            <>
                                <IconButton  disabled={!this.state.loaded} onClick={()=>this.changeCalendayDay(false)} title={"Une journée avant"}>
                                    <ArrowBackIos/>
                                </IconButton> 
                                <TextField
                                    id="date"
                                    label="Date "
                                    type="date"
                                    disabled={!this.state.loaded}
                                    defaultValue={ this.state.selectedDate }
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.handleChange('cal')}
                                    value={this.state.selectedDate}
                                />      
                                <IconButton  disabled={!this.state.loaded} onClick={()=>this.changeCalendayDay(true)} title={"Une journée après"}>
                                    <ArrowForwardIos/>
                                </IconButton>                                                        
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        { this.props.mode!='dashBoard' &&                           
                            
                            <div style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', margin:'20px 0px', flexWrap: 'wrap' }}>
                                {this.state.zones.map(item => (
                                    <ZoneButton 
                                        zone_id={item.id}
                                        zoneName={item.zone_name + (this.shootCount(item.id) > 0 ? " (" + this.shootCount(item.id) + ")" : "")}
                                        active={this.activeZone(item.id)}
                                        buttonClick={() => this.toggleZone(item.id)}
                                    />
                                ))}
                            </div>                           
                        }
                    </Grid>
                </Grid>

                {this.state.loaded==false || this.state.loadingData == true &&   
                    <PleaseWait />                      
                }

                { this.state.loaded==true && !this.state.loadingData &&
                    <Grid container spacing={8}>                        
                        <Grid item md={6} className={classes.mainCol} style={{width:'100%'}}>
                            {this.state.techs.length==0 &&  this.props.mode!='dashBoard' &&
                                <Paper className={classes.noShooting} variant="h3">
                                    Aucun photographe de disponible pour cette date.
                                </Paper>
                            }
                            {this.state.techs.filter(this.filterTechs).map(row => (
                                <Card 
                                    key={row.techId} 
                                    className={classes.card} 
                                    style={{ 
                                        backgroundColor:this.state.dropZoneColor[row.techId]                                                                                                                    
                                    }} 
                                    onDragOver={this.dragOver(row)} 
                                    onDragEnter={this.dragEnter(row)}
                                    onDragLeave={this.dragLeave(row)}
                                    onDrop={this.dragDrop(row)}>
                                    <CardContent>
                                        {this.props.mode=='dashBoard' &&
                                            <div>
                                                <Typography  variant="h5" color="textPrimary" gutterBottom>
                                                    {row.techName} 
                                                </Typography>

                                                <ListWithDays days={row.days}/>
                                            </div>
                                        }

                                        {this.props.mode!='dashBoard' &&
                                            <div>
                                                <Typography  variant="h5" color="textPrimary" gutterBottom>
                                                    {row.techName}                                                     
                                                    
                                                    <IconButton  disabled={this.state.loadingLocks} onClick={()=>this.toggleLockForTech(row.techId)} title={this.isTechDayLocked(row.techId) ? "Unlock Tech":"Lock Tech"}>
                                                        <Icon>{this.isTechDayLocked(row.techId) ? "lock_open":"lock"}</Icon>
                                                    </IconButton>                                                    
                                                </Typography>
                                                {row.shootings.length > 0 ? (
                                                    <ExpansionPanel defaultExpanded={this.state.shootingsExpanded}>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">  
                                                            <div style={{display:'flex', flexDirection:'column'}}>                                                              
                                                                <Typography variant="h7">
                                                                    {row.shootings.length} capture{row.shootings.length > 1 ? "s: " : ": "} {row.distanceTotal} kms
                                                                </Typography>
                                                                
                                                                {row.techDailyActivity.map(item => (
                                                                    <div style={{margin:10}}>
                                                                        {
                                                                            item.unavailability ? (
                                                                                <>
                                                                                    <Typography style={{fontWeight:'bold', color:'red'}}>{item.schedule + (item.summary != "" && item.summary != null ? " - (" + item.summary + ")" : " - (Indisponibile)")}</Typography>
                                                                                    <Typography>{row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city}</Typography>
                                                                                </>
                                                                            ) : 
                                                                            (    
                                                                                this.onlyShoot(row.techDailyActivity, item) ? (
                                                                                    <>                                                                                    
                                                                                        <Typography style={{fontWeight:'bold', color:'blue'}}>Départ du {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city} à {this.getDepartureTime(item)}</Typography>
                                                                                        <br></br>
                                                                                        <Typography style={{fontWeight:'bold'}}>{item.schedule} - ({item.distance} kms - {this.readableTransitTime(item.transit_time)})</Typography>
                                                                                        <Typography>{item.address + (item.zone != null ? " (" + item.zone.zone_name + ")": "")}</Typography>
                                                                                        <br></br>
                                                                                        <Typography style={{fontWeight:'bold', color:'blue'}}>Arrivé au {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city} à {this.getArrivalTime(row.techDailyActivity, item)}</Typography>
                                                                                    </>
                                                                                ) : this.firstShoot(row.techDailyActivity, item) ? (
                                                                                    <>                                                                                    
                                                                                        <Typography style={{fontWeight:'bold', color:'blue'}}>Départ du {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city} à {this.getDepartureTime(item)}</Typography>
                                                                                        <br></br>
                                                                                        <Typography style={{fontWeight:'bold'}}>{item.schedule} - ({item.distance} kms - {this.readableTransitTime(item.transit_time)})</Typography>
                                                                                        <Typography>{item.address + (item.zone != null ? " (" + item.zone.zone_name + ")": "")}</Typography>
                                                                                    </>
                                                                                ) : this.lastShoot(row.techDailyActivity, item) ? (                                                                                    
                                                                                    <>                                                                                    
                                                                                        <Typography style={{fontWeight:'bold'}}>{item.schedule} - ({item.distance} kms - {this.readableTransitTime(item.transit_time)})</Typography>
                                                                                        <Typography>{item.address + (item.zone != null ? " (" + item.zone.zone_name + ")": "")}</Typography>
                                                                                        <br></br>
                                                                                        <Typography style={{fontWeight:'bold', color:'blue'}}>Arrivé au {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city} à {this.getArrivalTime(row.techDailyActivity, item)}</Typography>
                                                                                    </>                                                                                    
                                                                                ) : 
                                                                                (                                                                                    
                                                                                    <>                                                                                    
                                                                                        <Typography style={{fontWeight:'bold'}}>{item.schedule} - ({item.distance} kms - {this.readableTransitTime(item.transit_time)})</Typography>
                                                                                        <Typography>{item.address + (item.zone != null ? " (" + item.zone.zone_name + ")": "")}</Typography>
                                                                                    </>                                                                                    
                                                                                )
                                                                            )
                                                                        }
                                                                        
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails style={{flexDirection:"column"}}>
                                                
                                                            {row.shootings.map(item => (
                                                                    <Card 
                                                                        key={item.shootingId} 
                                                                        className={classes.itemCard} 
                                                                        draggable
                                                                        onDragStart={this.dragStart(item,row)} 
                                                                        onDragEnd={this.dragEnd()}>
                                                                        <CardContent style={{padding:10}}>
                                                                        
                                                                            <Button                                                                                  
                                                                                variant="outlined"
                                                                                color="inherit"
                                                                                id="mobil-unassign"
                                                                                className={classes.unassignButton}
                                                                                onClick={this.deAssignTech(item)}>
                                                                                Déassigner
                                                                            </Button>                                                                            
                                                                            <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                                                                                {item.seasonal==true &&
                                                                                    <span style={{color:'#3f51b5',fontWeight:'bold'}}>Saisonnière</span>    
                                                                                }
                                                                                {
                                                                                    item.seasonal==true && item.blue_hour ? (<span style={{color:'#3f51b5',fontWeight:'bold'}}>/</span>) : null
                                                                                }
                                                                                {item.blue_hour==true &&
                                                                                    <span style={{color:'#3f51b5',fontWeight:'bold'}}> Heure Bleue</span>    
                                                                                }
                                                                                {
                                                                                    item.drone==true && (item.seasonal || item.blue_hour) ? (<span style={{color:'#3f51b5',fontWeight:'bold'}}>/</span>) : null
                                                                                }
                                                                                {item.drone==true &&
                                                                                    <span style={{color:'#3f51b5',fontWeight:'bold'}}> Drone</span>    
                                                                                }
                                                                            </Typography>
                                                                            { item.schedulerOverride == true ? 
                                                                                (<Typography>                                                                                    
                                                                                    <span                                                                                    
                                                                                        style={{                                                                                        
                                                                                            color:'red',
                                                                                            fontWeight:'bold'}}>
                                                                                        {"HORAIRE IGNORÉ"}
                                                                                    </span> 
                                                                                </Typography>) : null
                                                                            }
                                                                            { item.force_assigned == true ? 
                                                                                (<Typography>                                                                                    
                                                                                    <span                                                                                    
                                                                                        style={{                                                                                        
                                                                                            color:'red',
                                                                                            fontWeight:'bold'}}>
                                                                                        {"ASSIGNATION FORCÉE"}
                                                                                    </span>  
                                                                                </Typography>) : null
                                                                            }
                                                                            { item.use_backup == true ? 
                                                                                (<Typography>
                                                                                    <span                                                                                    
                                                                                        style={{                                                                                        
                                                                                            color:'red',
                                                                                            fontWeight:'bold'}}>
                                                                                        {"BACKUP"}
                                                                                    </span>                                                                                    
                                                                                </Typography>) : null
                                                                            }
                                                                            { item.use_backup == true || item.zone == null || item.zone.id < 0 || item.schedulerOverride == true ? 
                                                                                (<Typography>
                                                                                    <span                                                                                    
                                                                                        style={{                                                                                        
                                                                                            color:'red',
                                                                                            fontWeight:'bold'}}>
                                                                                        {"NE PEUT PAS ÊTRE DÉSASSIGNÉE"}
                                                                                    </span>                                                                                    
                                                                                </Typography>) : null
                                                                            }
                                                                            <div style={{display:'flex',justifyContent:'flex-end'}} >
                                                                                <FormControl className={'select-multiple'} style={{width:150, marginBottom:10}}>
                                                                                    <InputLabel className="mobile-select" htmlFor="select-multiple"> 
                                                                                        Photographe
                                                                                    </InputLabel>
                                                                                    <Select className="mobile-select"
                                                                                        value={this.state.techs}
                                                                                        onChange={this.assignTech(item) }
                                                                                        input={<Input id="select-multiple"  value="foo"/>}>
                                                                                        {this.state.techs.filter(this.filterTechs).map(item => (
                                                                                            item.techName != row.techName ? (
                                                                                                <MenuItem key={item.techId} value={item.techId} >
                                                                                                    {item.techName}
                                                                                                </MenuItem>
                                                                                                ) : null
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </FormControl>                                                                
                                                                            </div> 
                                                                            <Typography variant="h7">
                                                                                <span 
                                                                                    onClick={()=>this.showListing(item)}
                                                                                    style={{
                                                                                        
                                                                                        color:'black',
                                                                                        cursor:'pointer'}}>
                                                                                    {item.address}
                                                                                </span>
                                                                            </Typography>
                                                                            <Typography >
                                                                                <span                                                                                    
                                                                                    style={{                                                                                        
                                                                                        color:'black',
                                                                                        fontWeight:'bold'}}>
                                                                                    {item.schedule}
                                                                                </span> 
                                                                            </Typography>
                                                                            {item.zone != null ? (                                                 
                                                                                <Typography>
                                                                                    {"Zone: "  + item.zone.zone_name }                                                                                   
                                                                                </Typography>   
                                                                                ) : 
                                                                                <Typography   color="secondary">
                                                                                    {"Zone: Indéterminée"}
                                                                                </Typography>                                                       
                                                                            }                                                            
                                                                            
                                                                            <Typography >
                                                                                <Link style={{color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                                    {"Courtier : " + item.broker}
                                                                                </Link>
                                                                            </Typography>

                                                                            {item.note ?
                                                                                (
                                                                                    <Typography component="p">
                                                                                        {item.note}
                                                                                    </Typography>
                                                                                ) : null
                                                                            }                
                                                                            <ExpansionPanel>
                                                                                <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/>
                                                                                <ExpansionPanelDetails style={{flexDirection:"column"}}>                                                                    
                                                                                    <Typography  cstyle={{fontWeight:"bold"}}>
                                                                                        <Link style={{fontWeight:'bold',color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                                            {"Courtier : " + item.broker}
                                                                                        </Link>
                                                                                    </Typography>   
                                                                                    <Typography >
                                                                                        {"Listing ID : " + item.listingId}
                                                                                    </Typography>
                                                                                    <Typography >
                                                                                        {"Shooting ID : " + item.shootingId}
                                                                                    </Typography>
                                                                                    <Typography component="p">
                                                                                        {item.distance} kms
                                                                                    </Typography>
                                                                                    {  item.banner!=null && 
                                                                                        <Typography component="p">
                                                                                            {item.banner}
                                                                                        </Typography>                                                                     
                                                                                    }
                                                                                    {item.reshoot==true &&
                                                                                        <Typography component="p" >                                                                            
                                                                                            Reshoot ({item.reshootIndex})                                                                            
                                                                                        </Typography>
                                                                                    }                                                                                    
                                                                                    <Typography component="p">
                                                                                        {item.rooms} pièces
                                                                                    </Typography>
                                                                                                                                                        
                                                                                    <Typography component="p">
                                                                                        Facturable :
                                                                                        <Checkbox className={classes.dispatchCheckbox}
                                                                                            onChange={this.handleBillableCheckboxChange(item,'tech',row.techId)}
                                                                                            checked={item.billable}
                                                                                            color="primary"/>    
                                                                                    </Typography>
                                                                                </ExpansionPanelDetails>
                                                                            </ExpansionPanel>                                                            
                                                                        </CardContent>
                                                                    </Card>
                                                                ))}
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                ) : null}
                                                <ExpansionPanel defaultExpanded={row.shootings.length == 0 || this.state.zoneDetailsExpanded}>
                                                <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/>                                                    
                                                    <ExpansionPanelDetails style={{flexDirection:"column"}}>  
                                                        {row.depot != null ? (
                                                            <Typography  variant="h6" gutterBottom>
                                                                {"Dépot: " }
                                                                <Typography  variant="subheading" color="textPrimary" gutterBottom>
                                                                    {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city}
                                                                </Typography>
                                                            </Typography>
                                                        ) : null}                                                     
                                                        {row.zone != "" ? (                                                 
                                                            <Typography  variant="h6" gutterBottom>
                                                                {"Zone Principale: "}
                                                                <Typography  variant="subheading" color="textPrimary" gutterBottom>
                                                                    {row.zone}
                                                                </Typography>
                                                            </Typography>   
                                                            ) : 
                                                            <Typography  variant="h6" color="secondary" gutterBottom>
                                                                {"Indéterminée"}
                                                            </Typography>                                                       
                                                        }

                                                        {row.backupZone != "" ? (                                                 
                                                            <Typography  variant="h6" gutterBottom>
                                                                {row.multipleBackupZones ? "Zones Secondaires: " : "Zone Secondaire: " }
                                                                <Typography  variant="subheading" color="textPrimary"  gutterBottom>
                                                                    {row.backupZone}
                                                                </Typography>
                                                            </Typography>   
                                                            ) : 
                                                            null                                                
                                                        }                                                                
                                                    
                                                        {(row.shootings && row.shootings.length > 0) ? (
                                                            <Typography  variant="h6"   gutterBottom>
                                                                Distance parcourue : 
                                                                <Typography  variant="subheading" color="textPrimary" gutterBottom>
                                                                    {row.distanceTotal}km
                                                                </Typography>
                                                            </Typography>
                                                        ) : null}                                             

                                                        {
                                                            typeof row.skills !== "undefined" ? (
                                                                <Typography  variant="h6" gutterBottom>
                                                                    {"Compétences: "}
                                                                    <Typography  variant="subheading" color="textPrimary" gutterBottom>
                                                                        {row.skills}
                                                                    </Typography>
                                                                </Typography>
                                                            ) : null
                                                        }
                                                        
                                                        {row.unavailabilities.length > 0 ? (
                                                            <Typography  variant="h6" gutterBottom>
                                                                Indisponibilités:
                                                            </Typography>
                                                        ) : null}
                                                        {row.unavailabilities.map(unavailability => (
                                                            <>
                                                                <Typography  variant="subtitle1"   gutterBottom>
                                                                    {moment(unavailability.start).format('HH:mm') + "-" + moment(unavailability.end).format('HH:mm') + (unavailability.summary != "" ? " " + unavailability.summary : "")}
                                                                </Typography>
                                                            </>
                                                        ))}
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </div>
                                        }
                                    </CardContent>
                                </Card>
                            ))}
                        </Grid>
                        <Grid item md={6} className={[classes.mainCol, classes.unassignedShoots]} style={{marginTop:this.state.scrollPosition}}>
                            {this.state.unAssignedShootings.map(row => (
                                <Card 
                                    key={row.techId} 
                                    className={[classes.card, classes.unassignedShoots]} 
                                    style={{backgroundColor:this.state.dropZoneColor[row.techId]}}
                                    onDragOver={this.dragOver(row)} 
                                    onDragEnter={this.dragEnter(row)}
                                    onDragLeave={this.dragLeave(row)}
                                    onDrop={this.dragDrop(row)}>

                                    <CardContent>
                                        <Grid container >
                                            <Grid item xs={8}>
                                                <Typography style={{minHeight:45}} variant="h6" gutterBottom>
                                                    {row.techName}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {row.shootings.filter(this.filterUnassignedShootings).map(item=> (                                                    
                                            <Card 
                                                key={item.shootingId}
                                                className={classes.itemCard}
                                                draggable style={{backgroundColor:item.backgroundColor}}
                                                onDragStart={this.dragStart(item,row)}
                                                onDragEnd={this.dragEnd()}>                                                   
                                    
                                                <CardContent>                                                   
                                                    <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                                                        {item.seasonal==true &&
                                                            <span style={{color:'#3f51b5',fontWeight:'bold'}}>Saisonnière</span>    
                                                        }
                                                        {
                                                            item.seasonal==true && item.blue_hour ? (<span style={{color:'#3f51b5',fontWeight:'bold'}}>/</span>) : null
                                                        }
                                                        {item.blue_hour==true &&
                                                            <span style={{color:'#3f51b5',fontWeight:'bold'}}> Heure Bleue</span>    
                                                        }
                                                        {
                                                            item.drone==true && (item.seasonal || item.blue_hour) ? (<span style={{color:'#3f51b5',fontWeight:'bold'}}>/</span>) : null
                                                        }
                                                        {item.drone==true &&
                                                            <span style={{color:'#3f51b5',fontWeight:'bold'}}> Drone</span>    
                                                        }
                                                    </Typography>
                                                    { this.props.mode!='dashBoard' &&     
                                                        <>       
                                                            { item.schedulerOverride == true ? 
                                                                (<Typography>                                                                                    
                                                                    <span                                                                                    
                                                                        style={{                                                                                        
                                                                            color:'red',
                                                                            fontWeight:'bold'}}>
                                                                        {"HORAIRE IGNORÉ - À ASSIGNER RAPIDEMENT"}
                                                                    </span> 
                                                                </Typography>) : null
                                                            }   
                                                            <div style={{display:'flex',justifyContent:'flex-end'}} >
                                                                <FormControl className={'select-multiple'} style={{width:150, marginBottom:10}}>
                                                                    <InputLabel className="mobile-select" htmlFor="select-multiple"> 
                                                                        Photographe
                                                                    </InputLabel>
                                                                    <Select className="mobile-select"
                                                                        value={this.state.techs}
                                                                        onChange={this.assignTech(item) }
                                                                        input={<Input id="select-multiple"  value="foo"/>}>
                                                                        {this.state.techs.filter(this.filterTechs).map(item => (
                                                                            <MenuItem key={item.techId} value={item.techId} >
                                                                                {item.techName}
                                                                            </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>                                                                
                                                            </div>                                            
                                                            <Typography variant="h7">
                                                                <span 
                                                                    onClick={()=>this.showListing(item)}
                                                                    style={{
                                                                        
                                                                        color:'black',
                                                                        cursor:'pointer'}}>
                                                                    {item.address + (item.zone != null ? " (" + item.zone.zone_name + ")": "")}
                                                                </span>
                                                            </Typography>

                                                            {item.zone != null ? (                                                 
                                                                <Typography>
                                                                    <span                                                                                    
                                                                        style={{                                                                                        
                                                                            color:'black',
                                                                            fontWeight:'bold'}}>
                                                                        {"Zone: "  + item.zone.zone_name }
                                                                    </span>                                                                                    
                                                                </Typography>   
                                                                ) : 
                                                                <Typography   color="secondary">
                                                                    {"Zone: Indéterminée"}
                                                                </Typography>                                                       
                                                            }                                                            
                                                            <Typography >
                                                                {item.schedule}
                                                            </Typography>
                                                            <Typography >
                                                                <Link style={{color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                    {"Courtier : " + item.broker}
                                                                </Link>
                                                            </Typography>

                                                            {item.note ?
                                                                (
                                                                    <Typography component="p">
                                                                        {item.note}
                                                                    </Typography>
                                                                ) : null
                                                            }             
                                                            
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/> 
                                                                <ExpansionPanelDetails style={{flexDirection:"column"}}>                                                                    
                                                                    <Typography  cstyle={{fontWeight:"bold"}}>
                                                                        <Link style={{fontWeight:'bold',color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                            {"Courtier : " + item.broker}
                                                                        </Link>
                                                                    </Typography>   
                                                                    <Typography >
                                                                        {"Listing ID : " + item.listingId}
                                                                    </Typography>
                                                                    <Typography >
                                                                        {"Shooting ID : " + item.shootingId}
                                                                    </Typography>
                                                                    {  item.banner!=null && 
                                                                        <Typography component="p">
                                                                            {item.banner}
                                                                        </Typography>                                                                     
                                                                    }
                                                                    {item.reshoot==true &&
                                                                        <Typography component="p" >                                                                            
                                                                            Reshoot ({item.reshootIndex})                                                                            
                                                                        </Typography>
                                                                    }
                                                                    
                                                                    <Typography component="p">
                                                                        {item.rooms} pièces
                                                                    </Typography>
                                                                                                                                         
                                                                    <Typography component="p">
                                                                        Facturable :
                                                                        <Checkbox className={classes.dispatchCheckbox}
                                                                            onChange={this.handleBillableCheckboxChange(item,'unassigned')}
                                                                            checked={item.billable}
                                                                            color="primary"/>    
                                                                    </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>                                                            
                                                        </>
                                                    }
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </CardContent>
                                </Card>
                            ))}                                    
                        </Grid>
                    </Grid>
                }
            </Paper>
        );
    }
}

 
function ListWithDays(props) {    
    return (
        <CardContent>  
            {Object.keys(props.days).map((day,idx) => (
                <Card key={idx} style={{backgroundColor:'#eeeeee', marginBottom:10}}>
                    <CardContent>
                        <Typography variant="h6" component="h6">
                            {day}                                
                        </Typography>

                        {props.days[day].map(item=>(
                            <Card key={item.shootingId} style={{marginBottom:10}}>
                                <CardContent>
                                    <Typography variant="h6" component="h6">
                                        {item.address}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {item.only360 ? "360 Seulement" : "2D + 360"}
                                    </Typography>
                                    {item.note ?
                                        (
                                            <Typography component="p">
                                                {item.note}
                                            </Typography>
                                        ) : null
                                    }
                                    <Typography component="p">
                                        {item.schedule}
                                    </Typography>
                                    <Typography component="p">
                                        {item.distance} kms
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}                   
                    </CardContent>
                </Card>
            ))}
        </CardContent>
    )
}
export default withRouter(withStyles(styles)(ShootingPlanner));