import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import ShootingTimeInformation from './ShootingTimeInformation'

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    innerGrid: {
        margin: 0,
        padding: 15
    }
});

/**
 * Class used to view a single ticket
 */
class ShootingDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { techs: [], techsdata: {}, startDate: moment().format('YYYY-MM-DD'), is_reloading: false };
    }

    componentWillMount() {
        this.reloadShootings();
    }

    reloadShootings = () => {
        this.setState({is_reloading:true},()=>{
            window.Hauslife.allDailyShootings(moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
                .then((res) => {
                    this.setState({ techsdata: res.data.data, techs: res.data.data.scheduledTechs, is_reloading: false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ is_reloading: false });
                });
        });
    };

    handleDateChange = (event) => {
        this.setState({ startDate: event.target.value }, () => {
            this.reloadShootings();
        });

    }

    drawSingleShooting = (shooting) => {
        return <ShootingTimeInformation shooting={shooting} />;
    }

    drawShootingsOfTech = (techobj) => {
        if (this.state.techsdata[techobj.id]) {
            return (
                <Paper style={{padding:10, marginBottom: 10}}>
                    <h3 style={{marginBottom:10}}>{techobj.firstname + " " + techobj.lastname}</h3>
                    <Grid spacing={5} container>
                        {this.state.techsdata[techobj.id].map(row => (
                            <Grid item sm={4} xs={12}>
                                {this.drawSingleShooting(row)}
                            </Grid>
                    ))}
                    </Grid>
                </Paper>
            );
        }

        return (
            <Paper style={{padding:10, marginBottom: 10}}>
                <h3 style={{marginBottom:10}}>{techobj.firstname + " " + techobj.lastname}</h3>
                <p>{techobj.firstname + " " + techobj.lastname} has no shootings</p>
            </Paper>
        );
    }

    render() {
        const { classes, match, intl } = this.props;

        return (
            <Grid container>
                <Grid className={classes.innerGrid} xs={12}>
                    <Typography variant="h4" component="h4"> Shooting Dashboard </Typography>
                    <TextField
                        id="date"
                        label="Date "
                        type="date"
                        disabled={this.state.is_reloading}
                        value={this.state.startDate}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={this.handleDateChange}
                    />
                </Grid>
                <Grid className={classes.innerGrid} xs={12}>
                    {this.state.is_reloading === true ?
                    (
                        <PleaseWait />
                    )
                    :
                    (
                        <>
                        {this.state.techs.map(row => (
                            this.drawShootingsOfTech(row)
                        ))}
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}

ShootingDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles)(ShootingDashboard)));
