const types = {
	'FETCHING_ZONES': 'FETCHING_ZONES',
	'FETCHING_ZONES_SUCCESS': 'FETCHING_ZONES_SUCCESS',
	'FETCHING_ZONES_FAILURE': 'FETCHING_ZONES_FAILURE',
	'ADD_NEW_ZONE': 'ADD_NEW_ZONE',
	'CANCEL_NEW_ZONE': 'CANCEL_NEW_ZONE',
	'SAVE_NEW_ZONE': 'SAVE_NEW_ZONE',
	'EDIT_ZONE': 'EDIT_ZONE',
	'EDIT_ZONE_SAVE': 'EDIT_ZONE_SAVE',
	'FETCHED_SHOOTINGS': 'FETCHED_SHOOTINGS',
	'FETCHED_TECHS': 'FETCHED_TECHS',
	'TOGGLE_DELETE_ZONE': 'TOGGLE_DELETE_ZONE',
	'TOGGLE_ZONE_SELECTION': 'TOGGLE_ZONE_SELECTION',
	'MAP_SHOOTINGS_WITH_TECH': 'MAP_SHOOTINGS_WITH_TECH',
	'SHOOT_ASSIGNED_SUCCESS': 'SHOOT_ASSIGNED_SUCCESS',
	'TOGGLE_ZONE_VISIBILITY': 'TOGGLE_ZONE_VISIBILITY',
	'ON_SAVE_DB': 'ON_SAVE_DB',
	'ON_SAVE_DB_FAILURE': 'ON_SAVE_DB_FAILURE'
}

export default types