import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes, { func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
 
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

var _ = require('lodash');

const styles = theme => ({
   
      title : {

        
       // border:'1px solid black',
        padding:10,
        margin:10
      },

      maingGrid:{
      //  backgroundColor:'red',
        height:'100%'
        /* display: 'flex',
        width: '100%',
        height:'100%',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'flex-start ',
        flexGrow: 1, */
      },
      contentGrid : {

          //  width:'100%',
           // height:'100%',
         //s   backgroundColor:'salmon',
            //overflowY:'scroll'

      },
      techGrid: {
       //   display : 'flex'
       overflowY:'scroll',
       paddingLeft:20
      },
      detailGrid:{
       // display : 'flex'
       //backgroundColor:'#f0f0f0'
      },
      card :{
        position:'absolute',
         margin:5
          // height:'100%',
       //  height:100,
          //minHeight:100,
        //  overflowY:'scroll',
         
      },
      textField: {
        marginRight:30
      },
      refreshScheduleButton:{
        float: 'right',
        marginRight: theme.spacing.unit,
        width: 200,
        backgroundColor:'green',
        color:'white'
      }
});

class TechManager extends React.Component {


    constructor(props) {
        super(props);
       
        this.state = {
            users : [],
            currentUser:null,
            startDate:moment().format('YYYY-MM-DD'),
            endDate:moment().add(1, 'days').format('YYYY-MM-DD') ,
            techInfo:{},
            alertText: ""
        }
    }

    componentWillMount() { 
        if(this.isAdmin()){
            window.Hauslife.getUsers({role:4})
                .then((res) => {
                    this.setState({ users: res.data.user });
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else{
            this.setState({currentUser: window.Hauslife.userObject.firstname + ' ' + window.Hauslife.userObject.lastname}, ()=>{
                this.refreshShooting(window.Hauslife.userObject.id, this.state.currentUser);
            })            
        }
    }

    toHHMMSS = function (value) {
        var sec_num = parseInt(value, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }

    refreshShooting = (techId,userName) => {
        window.Hauslife.shootingFromTech(techId,this.state.startDate, this.state.endDate ,true)
            .then((res) => {
                var shootings = res.data.data[techId];

                var netInfo ={
                    distance :0,
                    tempsAvg:0,
                    temps:0,
                    techId:techId,
                    shootingCount:0
                }

                if(shootings==undefined){
                    this.setState({ currentUser:userName, techInfo :netInfo });
                    return;
                }

                netInfo =  shootings.reduce( function(acc,value){
                    if(value.distance!=null){
                        acc.distance+=value.distance;
                    }

                    if(value.endtime!=null){
                        var end = new Date(value.endtime);
                        var start = new Date(value.scheduleddate);
                        acc.temps +=   Math.abs( end.getTime()-start.getTime() );
                    }
                    return acc;
                },netInfo);

                netInfo.tempsAvg = netInfo.temps/shootings.length;
                netInfo.shootingCount=shootings.length;
                netInfo.tempsAvg = this.toHHMMSS ( (netInfo.tempsAvg / 1000)  );
                netInfo.temps = this.toHHMMSS( (netInfo.temps / 1000) );
                netInfo.shootings = shootings;
                
                this.setState({ currentUser:userName, techInfo :netInfo });
            })
            .catch((e) => {
                console.log(e);
            }); 
    }

    techClick = (techId,userName) => event => {
        this.refreshShooting(techId, userName);
    }
    
    handleChange = name => event => {
        var obj ={};
        obj[name]= event.target.value;

        this.setState( obj,function(error,result){
            if(this.state.currentUser!=null){
                this.techClick(this.state.techInfo.techId,this.state.currentUser)();
            }
        });
    }

    isAdmin = () => {
        return window.Hauslife.userObject.role==2 || window.Hauslife.userObject.role==1;
    }

    generatePDF  = () => { 
        var price_per_km = 0.45;

        let shootingList = [[
            {text:'Adresse',style: 'tableHeader'},
            {text:'Date',style: 'tableHeader'},
            //{text:'KM',style: 'tableHeader'},
            //{text:'$',style: 'tableHeader'}
        ]];

        var sortedShootings = _.sortBy(this.state.techInfo.shootings, function(o) { return new moment(o.scheduleddate); });

        for(var i = 0; i < sortedShootings.length; i++)
        {
            let shooting = sortedShootings[i];
            
            shootingList.push([
                shooting.listing.address+", "+shooting.listing.city,
                moment(shooting.scheduleddate).format("YYYY-MM-DD"),
                //shooting.distance,
                //(shooting.distance*price_per_km).toFixed(2)+" $"
            ]);
        }

        /*shootingList.push([
            {text:'Total',style: 'tableHeader'},
            ''
            //{text:this.state.techInfo.distance,style: 'tableHeader'},
            //{text:(this.state.techInfo.distance*price_per_km).toFixed(2)+' $',style: 'tableHeader'}
        ]);*/

        var dd = {
            content: [
                {text: 'Rapport des captures du '+this.state.startDate+' au '+ this.state.endDate, style: 'header'},
                {text: 'Nom: ' + this.state.currentUser, style: 'subheader'},
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        widths: ['*', 80, 50, 50],
                        body: shootingList
                    }
                },
                //'Le kilomètrage est payé au taux de '+price_per_km+'$CAD du Kilomètre.',
            ],
            footer:{text: 'Rapport produit le : ' + moment().format("YYYY-MM-DD"), style: 'footer'},
            pageMargins: [ 40, 90, 40, 60 ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                footer: {
                    fontSize: 10,
                    color:"#aaa",
                    margin: [30, 30, 30, 30]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };

        pdfMake.createPdf(dd).open();
    }

    alertClose = () => {
        this.setState({ show_schedule_alert: false });
    }

    render() {
        const { classes } = this.props;
        return (     
                        <Grid className={classes.maingGrid} 
                              container
                              direction="column"
                              alignItems="stretch"
                              wrap="nowrap"
                              >

                            <AlertDialog 
                                show={this.state.show_schedule_alert} 
                                title={"Mise à jour des horaires"} 
                                text={"La mise à jour a été complétée avec succès"} 
                                confirm={false} 
                                didClose={this.alertClose}
                                />
                           <Grid   className={classes.title}>
                                <Typography variant="h4" component="h4"> Rapport des captures </Typography>   
                           </Grid>

                           <Grid className={classes.contentGrid}  xs={12}>
                                <Grid  style={{height:'100%'}} container  
                                      direction="row"
                                      justify="flex-start"
                                      wrap="nowrap"
                                      >

                                    {this.isAdmin() ? (
                                        <Grid xs={6} xl={3} className={classes.techGrid}>
                                            <div style={{display:'block',position:'relative',width:'100%',height:'100%',minHeight:0,overflowY:'scroll'}}> 
                                                <div className={classes.card}>
                                                        <List  >
                                                            {this.state.users.map(row => (
                                                            <ListItem button key={row.id} onClick={ this.techClick(row.id,row.firstname+' '+row.lastname) }>
                                                                <ListItemText primary={row.firstname+' '+row.lastname} />
                                                            </ListItem>
                                                            ))}
                                                        </List>
                                                </div>
                                            </div>
                                        </Grid>
                                    ) : null}
                                    
                                    <Grid  xs={12}  className={classes.detailGrid}>
                                        <Grid    
                                            container
                                            direction="column"
                                            alignItems="stretch"
                                            wrap="nowrap"
                                            >
                                                {this.isAdmin() ? (
                                                    <Grid  style={{backgroundColor:'white',borderBottom:'1px solid #f0f0f0'}} xs={12}>
                                                            <Typography variant="h6"> {this.state.currentUser} </Typography> 
                                                    </Grid>     
                                                ) : null }                               

                                                <Grid  style={{backgroundColor:''}} xs={12}>

                                                    <div style={{margin:20}}>

                                                        <TextField
                                                                id="date"
                                                                label="Date "
                                                                type="date"
                                                                defaultValue={ this.state.startDate }
                                                                className={classes.textField}
                                                                InputLabelProps={{
                                                                                shrink: true,
                                                                                }}
                                                                onChange={this.handleChange('startDate')}
                                                                />
                                                            

                                                            <TextField
                                                                id="date"
                                                                label="Date "
                                                                type="date"
                                                                defaultValue={ this.state.endDate }
                                                                className={classes.textField}
                                                                InputLabelProps={{
                                                                                shrink: true,
                                                                                }}
                                                                onChange={this.handleChange('endDate')}
                                                                />

                                                            <Button onClick={this.generatePDF}>Télécharger le rapport PDF</Button>
                                                    </div>

                                                </Grid>        

                                               {this.state.currentUser &&         
                                                <Grid sx={12}>

                                                    <div style={{marginLeft:10,marginTop:10}}>
                                                        <Typography style={{display:'inline',marginLeft:0}}  variant="h5" > Nombre de capture :</Typography>
                                                        <Typography  style={{display:'inline',marginLeft:0,fontWeight:'bold'}} variant="h6" > {this.state.techInfo.shootingCount}</Typography>
                                                    </div>
                                                    {this.isAdmin() ? (
                                                        <>
                                                            <div style={{marginLeft:10,marginTop:10}}>
                                                                <Typography style={{display:'inline',marginLeft:0}}  variant="h5" > Distance :</Typography>
                                                                <Typography  style={{display:'inline',marginLeft:0,fontWeight:'bold'}} variant="h6" > {this.state.techInfo.distance} Km</Typography>
                                                            </div>

                                                            <div style={{marginLeft:10,marginTop:10}}>
                                                                <Typography style={{display:'inline',marginLeft:0}}  variant="h5" > Moyenne temps :</Typography>
                                                                <Typography  style={{display:'inline',marginLeft:0,fontWeight:'bold'}} variant="h6" > {this.state.techInfo.tempsAvg}</Typography>
                                                            </div>

                                                            <div style={{marginLeft:10,marginTop:10}}>
                                                                <Typography style={{display:'inline',marginLeft:0}}  variant="h5" > Total temps:</Typography>
                                                                <Typography  style={{display:'inline',marginLeft:0,fontWeight:'bold'}} variant="h6" > {this.state.techInfo.temps}</Typography>
                                                            </div>
                                                        </>
                                                    ) : null }
                                                </Grid>
                                               }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                           </Grid>
                </Grid>
        );

    }
}

class TechDetail extends React.Component {
    constructor(props) {
        super(props);

       
        this.state = {
            users : []
        };
    }
    render() {
        var classes =  {  
            
            
        }    
         
        return (      
                             <Grid    
                                    container
                                    direction="column"
                                    alignItems="stretch"
                                    wrap="nowrap"
                                    >

                                    <Grid  style={{backgroundColor:'white',borderBottom:'1px solid #f0f0f0'}} xs={12}>
                                            <Typography variant="h4"> UserName </Typography> 
                                    </Grid>                                    

                                    <Grid  style={{backgroundColor:''}} xs={12}>

                                    <div style={{margin:20}}>

                                        <TextField
                                                id="date"
                                                label="Date "
                                                type="date"
                                                defaultValue={ this.state.selectedDate }
                                                className={classes.textField}
                                                InputLabelProps={{
                                                                shrink: true,
                                                                }}
                                                 
                                                />
                                    </div>

                                </Grid>        
                                            
                                <Grid sx={12}>
                                     
                                </Grid>

                                <Grid sx={12}>
                                    
                                </Grid>
                                

                            </Grid>

                     );
    }
}

export default withRouter(withStyles(styles)(TechManager));