import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { injectIntl, intl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import HausError from './HausError.js'
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import withWidth from '@material-ui/core/withWidth';

import CircularProgress from '@material-ui/core/CircularProgress';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CloseIcon from '@material-ui/icons/Close';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';

import Lightbox from 'react-image-lightbox';

import logo_dropbox from './../Dropbox.png';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

var _ = require('lodash');

const styles = theme => ({
    root: {
    },
    cell: {
        padding: 5
    },
    innerGrid: {
        margin: 0
    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    topButtons: {
        display: "flex",
        marginTop: 15,
        marginBottom: 15,
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%"
    }
});

/**
 * Class used to display tickets owned by the user
 */
class ViewDelivery extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            dropboxProgress: 0,
            savingToDropbox: false,
            photoIndex: 0,
            displayedPictres: [],
            displayedListing: null,
            currentFeedback: "",
            leftFeedback: null,
            leavingFeedback: false,
            openFeedback: false,
            pic_url: null,
            open: false,
            loading: true,
            rows: [] 
        };
    }

    componentWillMount() {
        this.refreshListing();
    }

    refreshListing = () => {
        const { match } = this.props;
        if (match.params.id != "undefined") {
            window.Hauslife.getDeliveries(match.params.id)
                .then((result) => {
                    console.log(result.data.listing);

                    //reorder pictures
                    if(result.data.listing.pictures && result.data.listing.pictures.length > 0)
                    {
                        let pictures = result.data.listing.pictures;
                        var sortedpictures = _.orderBy(pictures, ['shooting_id', 'picture_index'], ['asc', 'asc']);
                        result.data.listing.pictures = sortedpictures;
                    }

                    this.setState({ loading: false, listing: result.data.listing });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                });
        }
    };

    handleClickOpen = (pictures, index) => {
        let apictures = [];

        for (var i = 0; i < pictures.length; i++) {
            apictures.push(pictures[i].distrib_url);
        }

        this.setState({ photoIndex: index, displayedPictres: apictures, open: true });
    };

    handleClickOpenFeedback = (picture) => {
        if (picture.feedback_ticket_id != null) {
            this.props.history.push({ pathname: "/tickets/" + picture.feedback_ticket_id });
        }
        else {
            this.setState({ leftFeedback: null, picture: picture, openFeedback: true });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCloseFeedback = () => {
        if (this.state.leavingFeedback) return;

        this.setState({ openFeedback: false });

        this.refreshListing();
    };

    handleFeedbackChange = (event) => {
        this.setState({ currentFeedback: event.target.value });
    };

    IsDisplayingMe = (listing_id) => {
        return this.state.displayedListing === listing_id;
    };

    displayListingPictures = (listing_id) => {
        this.setState({ displayedListing: listing_id });
    };

    handSendFeedback = () => {
        this.setState({ leavingFeedback: true }, () => {
            window.Hauslife.leaveFeedbackOnPicture(this.state.picture, this.state.currentFeedback)
                .then(res => {
                    this.setState({ leavingFeedback: false, leftFeedback: res.data });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ leavingFeedback: false });
                })
        });
    };

    downloadFile = (file_url) => {
        window.location = file_url;
    };

    saveToDropbox = (pictures) => {

        let apictures = [];

        for (var i = 0; i < pictures.length; i++) {
            apictures.push({ url: pictures[i].distrib_url, filename: (pictures[i].picture_index + 1) + "_" + pictures[i].shooting_id + ".jpg" });
        }

        let thisRef = this;

        var options = {
            files: apictures,

            // Success is called once all files have been successfully added to the user's
            // Dropbox, although they may not have synced to the user's devices yet.
            success: function () {
                // Indicate to the user that the files have been saved.
                thisRef.setState({ savingToDropbox: false });
            },

            // Progress is called periodically to update the application on the progress
            // of the user's downloads. The value passed to this callback is a float
            // between 0 and 1. The progress callback is guaranteed to be called at least
            // once with the value 1.
            progress: function (progress) {
                //console.log("importing " + (progress * 100) + " %");
                thisRef.setState({ savingToDropbox: true, dropboxProgress: progress });
            },

            // Cancel is called if the user presses the Cancel button or closes the Saver.
            cancel: function () { },

            // Error is called in the event of an unexpected response from the server
            // hosting the files, such as not being able to find a file. This callback is
            // also called if there is an error on Dropbox or if the user is over quota.
            error: function (errorMessage) {
                console.log(errorMessage);
            }
        };

        //console.log(options);
        window.Dropbox.save(options);
    };

    generateUniqUrl = (listing_id) =>
    {
        window.Hauslife.generateListingDeliveryToken(listing_id)
        .then(res=>{
            this.refreshListing();
        })
        .catch(e=>{
            console.log(e);
        });
    }

    updatePictureVisibility = (picture_id, _public) =>
    {
        window.Hauslife.updatePictureVisibility(picture_id, _public)
        .then(res=>{
            this.refreshListing();
        })
        .catch(e=>{
            console.log(e);
            this.refreshListing();
        });
    }

    render() {
        const { classes, intl, fullScreen, width } = this.props;

        console.log(width)
        var row = this.state.listing;

        let momentofopen = "?"+moment().format('MMMM-DD-YYYY-h-mm');

        return (
            <div>
                {this.state.open && (
                    <Lightbox
                        mainSrc={this.state.displayedPictres[this.state.photoIndex]}
                        nextSrc={this.state.displayedPictres[(this.state.photoIndex + 1) % this.state.displayedPictres.length]}
                        prevSrc={this.state.displayedPictres[(this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length]}
                        onCloseRequest={() => this.setState({ open: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (this.state.photoIndex + 1) % this.state.displayedPictres.length,
                            })
                        }
                    />
                )}

                <Dialog
                    fullScreen={this.props.fullScreen}
                    open={this.state.openFeedback}
                    onClose={this.handleCloseFeedback}
                    fullWidth={true}
                >
                    {this.state.leftFeedback === null && <DialogTitle id="form-dialog-title"><FormattedMessage id="deliveries.feedback_dialog_title" /></DialogTitle>}

                    {this.state.leavingFeedback &&
                        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CircularProgress fullWidth variant="determinate" value={this.state.dropboxProgress} className={classes.progress} />
                        </DialogContent>
                    }

                    {(!this.state.leavingFeedback && this.state.leftFeedback !== null) &&
                        <DialogContent>
                            <Grid container justify={"center"} className={classes.root}>
                                <Grid item xs={12}>
                                    <Typography align={"center"}><Icon style={{ color: green[600], fontSize: 120 }}>check_circle</Icon></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h3" style={{ margin: 15 }} align={"center"}><FormattedMessage id="deliveries.received_feedback" /></Typography>
                                </Grid>
                            </Grid>

                        </DialogContent>
                    }

                    {(!this.state.leavingFeedback && this.state.leftFeedback === null) &&
                        <DialogContent>
                            <DialogContentText>
                                <FormattedMessage id="deliveries.explain_feedback" />
                            </DialogContentText>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                fullWidth
                                value={this.state.multiline}
                                onChange={this.handleFeedbackChange}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </DialogContent>
                    }

                    {this.state.leftFeedback === null &&
                        <DialogActions>
                            <Button disabled={this.state.leavingFeedback} onClick={this.handleCloseFeedback} color="primary">
                                <FormattedMessage id="deliveries.cancel" />
                            </Button>
                            <Button disabled={this.state.leavingFeedback} onClick={this.handSendFeedback} color="primary">
                                <FormattedMessage id="deliveries.send_feedback" />
                            </Button>
                        </DialogActions>
                    }

                    {this.state.leftFeedback !== null &&
                        <DialogActions>
                            <Button onClick={this.handleCloseFeedback} color="secondary"><FormattedMessage id="deliveries.close" /></Button>
                            <LinkButton color="primary" to={"/tickets/" + this.state.leftFeedback.ticket.id}>
                                deliveries.view_left_feedback
                    </LinkButton>
                        </DialogActions>
                    }

                </Dialog>

                {(this.state.loading == true) ? (<PleaseWait />) : (
                    <div>
                        {row ? (
                            <div>
                                <div className={classes.topButtons}>
                                    <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                        {row.address + (row.app ? ", # " + row.app + " " : "") + ", " + row.city}
                                    </Typography>
                                    <Button style={{ display: "flex", flexGrow: 1 }} disabled={this.state.savingToDropbox} onClick={() => { this.saveToDropbox(row.pictures); }} variant="outlined" color="primary">

                                        {this.state.savingToDropbox &&
                                            <CircularProgress size={18} />
                                        }
                                        {!this.state.savingToDropbox &&
                                            <span style={{ display: "inherit", justifyContent: "inherit", alignItems: "inherit", verticalAlign: "middle" }}><FormattedMessage id="deliveries.dropbox" /><img style={{ paddingLeft: 5 }} height={20} src={logo_dropbox} /></span>
                                        }
                                    </Button>
                                </div>
                                <div className={classes.topButtons}>
                                    <TextField style={{ display: "flex", flexGrow: 4, marginRight:10 }} label={"Sharable link"} variant="outlined" value={"https://livraison.hausvalet.ca/delivery/"+row.distribution_token} />
                                    {row.distribution_token == null &&
                                        <Button style={{ display: "flex", flexGrow: 1 }} onClick={() => { this.generateUniqUrl(row.id); }} variant="outlined" color="primary">
                                            Generate Unique url
                                        </Button>
                                    }
                                    {row.distribution_token != null &&
                                        <Button style={{ display: "flex", flexGrow: 1 }} target={"_blank"} href={"https://livraison.hausvalet.ca/delivery/"+row.distribution_token} variant="outlined" color="primary">
                                            Go to distribution page
                                        </Button>
                                    }
                                </div>

                                {window.Hauslife.userObject.role <= 2 &&
                                <Paper style={{margin:20, padding:10}}>
                                    <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                        Broker
                                    </Typography>
                                    <Link style={{color:"#000"}} to={"/users/edit/"+row.broker.id}>{row.broker.firstname + " " + row.broker.lastname}</Link>
                                    <p>{row.broker.email}</p>
                                    <p>{row.broker.phone_number}</p>
                                </Paper>
                                }

                                {(window.Hauslife.userObject.role <= 2 && row.shootings.length > 0) &&
                                <Paper style={{margin:20, padding:10}}>
                                    <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                        Tech
                                    </Typography>
                                    {row.shootings.map(shooting=>
                                        <div>
                                            <p>{"Shooting ID : " + shooting.id}</p>
                                            <p>Date : {moment(shooting.scheduleddate).format('LLL')} </p>
                                            {shooting.tech != null && <Link style={{color:"#000"}} to={"/users/edit/"+shooting.tech.id}>{"Tech : " + (shooting.tech != null ? shooting.tech.firstname + " " + shooting.tech.lastname : "null")}</Link>}
                                            {shooting.pp_tech != null && <Link style={{color:"#000"}} to={"/users/edit/"+shooting.pp_tech.id}>{"PP Tech : " + (shooting.pp_tech != null ? shooting.pp_tech.firstname + " " + shooting.pp_tech.lastname : "null")}</Link>}
                                        </div>
                                    )}
                                </Paper>
                                }
                                
                                {(row.state === 1 && row.pictures.length !== 0) &&
                                    <HausError
                                        style={{ marginTop: 15, marginBottom: 15 }}
                                        variant="info"
                                        message="deliveries.more_pictures_on_the_way"
                                    />
                                }

                                {row.pictures.length > 0 &&
                                    <div>
                                        <p style={{marginBottom:10}}>{row.pictures.filter((pic)=>pic.thumbnail_url!=null).length + " pictures"}</p>
                                        <GridList fullWidth cols={(width == "xl") ? 5 : (width == "lg") ? 4 : (width == "md") ? 3 : (width == "sm") ? 2 : 1} spacing={1} className={classes.gridList}>
                                            {row.pictures.filter((pic)=>pic.thumbnail_url!=null).map((picture, i) => {
                                                return (
                                                    <GridListTile key={picture.id} cols={1} rows={1}>
                                                        <img style={{ cursor: "pointer", filter: picture.public ? "":"grayscale(1)" }} onClick={() => { this.handleClickOpen(row.pictures.filter((pic)=>pic.thumbnail_url!=null), i); }} src={picture.thumbnail_url+momentofopen} />
                                                        <GridListTileBar
                                                            actionIcon={
                                                                <Button onClick={()=>{this.updatePictureVisibility(picture.id, !picture.public)}} style={{marginRight:5}} variant="contained">{picture.public ? "SET PRIVATE":"SET PUBLIC"}</Button>
                                                            }
                                                        />
                                                    </GridListTile>
                                                );
                                            })}
                                        </GridList>
                                    </div>
                                }
                            </div>
                        ) :
                            (
                                <Typography style={{ padding: 15 }} component="h3" variant="h3" align={"center"}><FormattedMessage id="deliveries.cannot_find" /></Typography>
                            )

                        }
                    </div>
                )
                }
            </div>
        );
    }
}

ViewDelivery.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withMobileDialog()(injectIntl(withRouter(withStyles(styles)(ViewDelivery)))));
